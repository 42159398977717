import { app } from "@microsoft/teams-js";
import React, { useEffect, useMemo } from "react";
import i18n from "../i18n";
import { DataProvider } from "../providers/DataProvider";

interface TeamsState {
    userContext?: app.Context;
    getAccessToken: () => Promise<string>;
}

interface TeamsContextProviderProps {
    children: React.ReactElement;
    dataProvider: DataProvider;
}

const TeamsContext = React.createContext<{ teamsState: TeamsState } | undefined>(undefined);

function TeamsContextProvider(props: TeamsContextProviderProps): JSX.Element {
    const { children, dataProvider } = props;
    const [teamsState, setTeamsState] = React.useState<TeamsState>({
        userContext: undefined,
        getAccessToken: () => { return Promise.resolve(''); }
    });

    const [ready, setReady] = React.useState(false);

    useEffect(() => {
        dataProvider.MsTeams.initialize();
        dataProvider.authenticate().then(() => {
            dataProvider.MsTeams.getContext().then(context => {
                i18n.changeLanguage(context.app.locale);
                setTeamsState({ userContext: context, getAccessToken: dataProvider.MsTeams.getAuthToken });
                setReady(true);
            });
        }).catch(() => dataProvider.MsTeams.notifyFailure({ reason: app.FailedReason.AuthFailed, message: "Authentication Failed" }));
    }, [dataProvider]);

    const teamsContext = useMemo(() => ({
        teamsState: teamsState,
        setTeamsState: setTeamsState
    }), [teamsState]);

    return (
        <TeamsContext.Provider value={teamsContext}>
            {ready && children}
        </TeamsContext.Provider>
    );
}

function useTeams() {
    const context = React.useContext(TeamsContext);
    if (context === undefined) {
        throw new Error('useTeams must be used within a TeamsProvider');
    }
    return context;
}

export { TeamsContext, TeamsContextProvider, useTeams };