import { ProgressIndicator } from '@fluentui/react';
import { FC } from 'react';

interface ProgressControlProps {
    visible: boolean,
    label: string
}

const ProgressControl: FC<ProgressControlProps> = (props) => {
    if (props.visible) {
        return <div style={{ width: '100%', paddingLeft: 5 }}><ProgressIndicator description={props.label} barHeight={4} /></div>;
    }
    return null;
};
export default ProgressControl;