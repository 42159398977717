import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageEN from './locate/en-US/translate.json';
import languageDA from './locate/da-DK/translate.json';
import languageFI from './locate/fi-FI/translate.json';
import languageNB from './locate/nb-NO/translate.json';
import languageSV from './locate/sv-SE/translate.json';
import languageNL from './locate/nl-NL/translate.json';

export const defaultNS = "translations";
export const resources = {
    en: languageEN,
    da: languageDA,
    fi: languageFI,
    nb: languageNB,
    sv: languageSV,
    nl: languageNL
} as const;

i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        ns: [defaultNS],
        defaultNS,
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        returnNull: false,
        react: {
            bindI18n: "languageChanged loaded",
            bindI18nStore: "added removed"
        }
    });

export default i18n;