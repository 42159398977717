import { FC } from 'react';
import { MessageBar } from '@fluentui/react';
import { archiveMessageBarStyle } from '../../styles/MergeStyleSets';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../helpers/Constants';
import { useFiles } from '../../context/FilesContext';

const FileListEmptyMessage: FC = () => {
    const { t } = useTranslation();
    const { currentFolder } = useFiles();

    return <MessageBar isMultiline={true} styles={archiveMessageBarStyle}>
        <p>
            {t('No files are available here yet! There are two ways to get started-')}<br /><br />
            {
                currentFolder?.fileId === Constants.HomeDir ?
                    t('Create or upload the files that you want to work with within this channel in the tab named Files')
                    :
                    t('Create or upload the files that you want to work with within this folder in the tab named Files')
            }<br /><br />
            {
                currentFolder?.fileId === Constants.HomeDir ?
                    t('Import files to this channel from 360° by pressing 360° file import')
                    :
                    t('Import files to this folder from 360° by pressing 360° file import')
            }<br /><br />
            {
                t('If you are still not sure on how to proceed, browse the help file')
            }
        </p>
    </MessageBar>;
};

export default FileListEmptyMessage;