import { Link, Modal, PartialTheme, ThemeProvider } from "@fluentui/react";
import { Component, ReactNode } from "react";


interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | undefined;
    showDetails: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false,
        error: undefined,
        showDetails: false
    };

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error, showDetails: false };
    }

    public render(): ReactNode {
        const lightTheme: PartialTheme = {
            semanticColors: {
                bodyBackground: 'white',
                bodyText: 'black'
            }
        };

        if (this.state.hasError) {
            return <ThemeProvider applyTo='body' theme={lightTheme}>
                <div style={{ margin: 30 }}>
                    <h1>Sorry, something went wrong :(</h1>
                    <Link onClick={() => this.setState({ showDetails: true })}>Error details</Link>
                    <Modal isOpen={this.state.showDetails} onDismiss={() => this.setState({ showDetails: false })}>
                        <div style={{ margin: 20 }}>
                            <p><strong>Error message: </strong>{this.state.error?.message}</p>
                            <p><strong>Stack trace:</strong></p>
                            <p>{this.state.error?.stack}</p>
                        </div>
                    </Modal>
                </div>
            </ThemeProvider>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

