import { mergeStyles, ThemeProvider, Stack, FontIcon } from "@fluentui/react";

function ConsentOK(): JSX.Element {
    const iconClass = mergeStyles({
        fontSize: 50,
        height: 50,
        width: 50,
        margin: '15px 25px',
        color: "green"
    });
    return <ThemeProvider>
        <Stack horizontal verticalAlign="start" horizontalAlign="center" style={{margin: "30px"}}>
            <FontIcon iconName="CheckMark" className={iconClass} />
            <Stack>
                <h1>Consent successfully done</h1>
            </Stack>
        </Stack>

    </ThemeProvider>;
}

export default ConsentOK;
