import { CheckboxVisibility, ConstrainMode, DefaultButton, Selection, DetailsList, DetailsListLayoutMode, DetailsRow, GroupHeader, IColumn, IDetailsGroupDividerProps, IDetailsRowProps, IGroup, IGroupHeaderProps, IIconProps, IRenderFunction, Link, MessageBar, PrimaryButton, Stack, SelectionMode, ScrollablePane, ScrollbarVisibility, TooltipHost, ISelection } from '@fluentui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { t } from "i18next";
import { EnumHoverCard, EnumSearchCriteria, EnumUIConfiguration, ICaseDocumentResponse, ICaseResult, IDocumentFileResult, IDocumentResult, IGetDocumentRequest, IProgressState, ISharepointDocumentItem, ISharepointFileResponse, ITeamServiceRequest } from '../../../model/model';
import { buttonStyles, classNames, detailListStyles, importPanelGroupDetailRowStyles, importPanelGroupHeaderStyles, scrollablePaneStyles, verticalGapStackTokens } from '../../../styles/MergeStyleSets';
import { ICommonProps } from '../../ICommonProps';
import { FileType } from '../../../helpers/FileType';
import DocumentEntityIcon from '../../../images/documententity.svg';
import ProtectedIcon from '../../../images/protected.svg';
import Searchbox from '../../common/Searchbox';
import i18n from '../../../i18n';
import ItemHoverCard from '../../common/ItemHoverCard';
import ProgressControl from '../../ProgressControl';
import LinkWithTooltip from '../../common/LinkWithTooltip';
import AllArchivedIcon from '../../../images/all-archived.svg';
import { Constants } from '../../../helpers/Constants';
import { useP360 } from '../../../context/P360Context';
import { useTeams } from '../../../context/TeamsContext';
import { MapTeamsContextToITeamRequest } from '../../../helpers/Mapper';
import { CombineUrl } from '../../../helpers/UrlHelper';
import ConfirmDialog from './ConfirmDialog';

interface ImportViewProps extends ICommonProps {
    itemImportPath?: ISharepointDocumentItem,
    updateDocumentItemStateHandler(data: ISharepointFileResponse[]): void,
    closePanelFileImport(): void
}

const messageBarIconProps: IIconProps = { iconName: "SkypeCircleCheck" };

const _columns: IColumn[] = [
    {
        key: 'column1', name: i18n.t('File Type'), fieldName: 'fileName', minWidth: 16, maxWidth: 16, className: classNames.fileIconCell, iconClassName: classNames.fileIconHeaderIcon, iconName: 'Page', isIconOnly: true, isResizable: true,
        onRender: (item: IDocumentFileResult) => (<img src={item.IconName} className={classNames.fileIconImg} alt={`${item.Format}`} />)
    },
    { key: 'fileColumn', name: i18n.t('Name'), isMultiline: true, fieldName: 'fileName', minWidth: 100, maxWidth: 200, isResizable: true, className: classNames.textSize }
];

const renderItemColumn = (item: IDocumentFileResult, index?: number, column?: IColumn): React.ReactNode => {
    const fieldContent = item[column?.fieldName as keyof IDocumentFileResult] as string;
    let lastChangedDate = undefined;
    if (item.LastChangedDate)
        lastChangedDate = new Date(item.LastChangedDate);
    if (column?.key === "fileColumn") {
        return <Stack style={{ height: '42px' }}>
            <span style={{ width: '300px' }} className={classNames.overflowTextDots}>
                <LinkWithTooltip tooltipText={item.Title ?? ''} text={item.Title ?? ''} />
            </span>
            <Stack horizontal horizontalAlign="start">
                <span style={{ fontSize: '12px' }}>{item.Format}</span>
                <div style={{ margin: '0px 5px 0px 5px' }} className={classNames.verticalSeperator} />
                <span style={{ fontSize: '12px' }}>{item.ModifiedBy}</span>
                <div style={{ margin: '0px 5px 0px 5px' }} className={classNames.verticalSeperator} />
                <span style={{ fontSize: '12px' }}>{lastChangedDate?.toLocaleDateString()}</span>
            </Stack>
        </Stack>;
    }
    return <span>{fieldContent}</span>;
};

const renderArchivedFileCheckBox = (): JSX.Element => (
    <div style={{ height: '32px', width: '48px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <TooltipHost content={i18n.t('The file is already in MS Teams')}>
            <img src={AllArchivedIcon} alt={i18n.t('The file is already in MS Teams')} style={{ height: '18px', width: '18px' }} />
        </TooltipHost>
    </div>
);

const onDetailRenderRow: IRenderFunction<IDetailsRowProps> = (props, defaultRender) => {
    if (!props || defaultRender === undefined) { return null; }
    const fileItem = props.item as IDocumentFileResult;
    const isArchived = JSON.parse(fileItem.Note?.toLowerCase() ?? "");
    return <DetailsRow {...props} styles={importPanelGroupDetailRowStyles} onRenderCheck={isArchived ? renderArchivedFileCheckBox : undefined} />;
};

const renderGroupTitle = (documentItem: IDocumentResult, uiConfiguration: EnumUIConfiguration | undefined): JSX.Element => {
    const document = { fileCount: 0, category: '', responsiblePerson: '', protectedDocument: false, tooltip: '' };
    if (documentItem.Files && documentItem.Files.length > 0)
        document.fileCount = documentItem.Files.length;
    if (documentItem.ResponsiblePerson)
        document.responsiblePerson = documentItem.ResponsiblePerson.Name;
    if (documentItem.Category)
        document.category = documentItem.Category.Code;
    if (documentItem.CustomFields) {
        document.protectedDocument = isDocumentProtected(documentItem);
        if (document.protectedDocument) {
            document.tooltip = `${i18n.t('AccessCodeTooltip', { accesscode: documentItem.AccessCodeDescription })}\n${i18n.t('ParagraphTooltip', { paragraph: documentItem.Paragraph })}`;
            if (uiConfiguration && uiConfiguration === EnumUIConfiguration.Sahke2.toString())
                document.tooltip = i18n.t('This document has a non-public publicity class');
        }
    }
    return (
        <Stack>
            <Stack horizontal horizontalAlign="start">
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        document.protectedDocument &&
                        <img src={ProtectedIcon} className={classNames.fileIconImg} style={{ width: '16px', height: '16px', paddingRight: '5px' }} title={document.tooltip} alt={document.tooltip} />
                    }
                    <img src={DocumentEntityIcon} className={classNames.fileIconImg} style={{ width: '16px', height: '16px' }} alt={i18n.t('Document')} />
                </span>
                <Stack style={{ paddingTop: '10px' }}>
                    <span style={{ width: '310px', paddingLeft: '15px' }} className={classNames.overflowTextDots}>
                        <ItemHoverCard enumHoverCard={EnumHoverCard.Document} documentResult={documentItem} />
                    </span>
                    <Stack horizontal horizontalAlign="start" style={{ paddingLeft: '15px' }}>
                        <span style={{ fontSize: '12px' }}>{`${document.fileCount} Files`}</span>
                        <div style={{ margin: '0px 5px 0px 5px' }} className={classNames.verticalSeperator} />
                        <span style={{ fontSize: '12px' }}>{document.category}</span>
                    </Stack>
                    <Stack>
                        <span style={{ fontSize: '12px', paddingLeft: '15px' }}>{document.responsiblePerson}</span>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const ImportView: FC<ImportViewProps> = (props) => {
    const { teamsState } = useTeams();
    const { p360State } = useP360();

    const { dataProvider, itemImportPath, updateDocumentItemStateHandler, closePanelFileImport } = props;
    const [documentsState, setDocumentsState] = useState<IDocumentFileResult[]>([]);
    const [allGroupCollapsed, setAllGroupCollapsed] = useState<boolean>(true);
    const [selectedCase, setSelectedCase] = useState<ICaseResult>();
    const [selectAllGroup, setSelectAllGroup] = useState<boolean>(false);
    const [progressState, setProgressState] = useState<IProgressState>({ loading: false });
    const [importInProgress, setImportInProgress] = useState<boolean>(false);
    const [groupItem, setGroupItem] = useState<IGroup[]>([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const loading = t('Loading...');
    const importProgressText = t('Importing files from 360...');

    const canSelectItem = useCallback((item: IDocumentFileResult): boolean => {
        return item.Note?.toLowerCase() !== "true";
    }, []);

    const [selectedFiles, setSelectedFiles] = useState<IDocumentFileResult[]>([]);
    const selection: ISelection<IDocumentFileResult> = useMemo((): ISelection<IDocumentFileResult> => {
        return new Selection<IDocumentFileResult>({
            canSelectItem: canSelectItem,
            onSelectionChanged: () => setSelectedFiles(selection.getSelection())
        });
    }, [canSelectItem]);

    const onClickExpandCollapseAll = useCallback(() => {
        const setCollapse = allGroupCollapsed !== true;
        setAllGroupCollapsed(setCollapse);
        const groups: IGroup[] = [];
        groupItem.forEach((gi) => {
            const updatedGroupItem = { ...gi };
            updatedGroupItem.isCollapsed = setCollapse;
            groups.push(updatedGroupItem);
        });
        setGroupItem(groups);
    }, [allGroupCollapsed, groupItem]);

    const onClickSelectAll = useCallback(() => {
        setSelectAllGroup(!selectAllGroup);
        selection.setAllSelected(!selectAllGroup);
    }, [selectAllGroup, selection]);

    const onClickClearAll = useCallback(() => {
        setSelectAllGroup(false);
        selection.setAllSelected(false);
    }, [selection]);

    const setDocumentsList = useCallback((caseDocumentResponse: ICaseDocumentResponse) => {
        let selectedCase: ICaseResult | undefined;
        if (caseDocumentResponse.CasesResult.Cases && caseDocumentResponse.CasesResult.Cases.length > 0) {
            selectedCase = { ...caseDocumentResponse.CasesResult.Cases[0], URL: CombineUrl(p360State.p360Url, caseDocumentResponse.CasesResult.Cases[0].URL) };
            setSelectedCase(selectedCase);
        }
        if (caseDocumentResponse.DocumentsResult.Documents) {
            const documentGroups = createGroups(caseDocumentResponse.DocumentsResult.Documents, selectedCase, p360State.p360Url);
            const fileGroupItems = createGroupItems(documentGroups);
            setGroupItem(documentGroups);
            setDocumentsState(fileGroupItems);
        }
        else {
            setGroupItem([]);
            setDocumentsState([]);
        }

        setAllGroupCollapsed(true);
        setProgressState({ loading: false });
    }, [p360State.p360Url]);

    const getDocumentsHandler = useCallback((entityNumber: string, searchCriteria: EnumSearchCriteria) => {
        setProgressState({ loading: true, text: loading });
        if (searchCriteria === EnumSearchCriteria.Case) {
            const getDocumentRequest: IGetDocumentRequest = { caseNumber: entityNumber, includeFiles: true };
            dataProvider?.P360?.getDocuments(getDocumentRequest).then((res) => {
                setDocumentsList(res.data);
            });
        }
        else if (searchCriteria === EnumSearchCriteria.Document) {
            const getDocumentRequest: IGetDocumentRequest = { documentNumber: entityNumber, includeFiles: true };
            if (p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.GetDocumentsByDocumentNumber)) {
                dataProvider?.P360?.getDocumentsByDocumentNumber(getDocumentRequest).then((res) => {
                    setDocumentsList(res.data);
                });
            }
        }
    }, [loading, dataProvider?.P360, setDocumentsList, p360State.backendCapabilities?.Capabilities]);

    const onRenderTitle: IRenderFunction<IGroupHeaderProps> = useCallback((headerProps, defaultRender) => {
        if (!headerProps) { return null; }
        if (defaultRender === undefined) { return null; }
        if (headerProps.group?.data) {
            const documentItem = headerProps.group?.data as IDocumentResult;
            return renderGroupTitle(documentItem, p360State.backendCapabilities?.Configuration.UIConfiguration);
        }
        return <></>;
    }, [p360State.backendCapabilities?.Configuration.UIConfiguration]);

    const onRenderGroupHeader: IRenderFunction<IDetailsGroupDividerProps> = (headerProps, defaultRender) => {
        if (!headerProps) { return null; }
        if (defaultRender === undefined) { return null; }
        return (
            <GroupHeader {...headerProps} styles={importPanelGroupHeaderStyles} onRenderTitle={onRenderTitle} />
        );
    };

    const importFilesHandler = useCallback(async (includeProtectedDocuments: boolean) => {
        if (itemImportPath) {
            setImportInProgress(true);
            const teamRequest: ITeamServiceRequest = {
                accessToken: await teamsState.getAccessToken(),
                teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext),
                driveId: itemImportPath.fileDriveId,
                fileId: itemImportPath.fileId,
                fileRecnos: []
            };

            setShowConfirmationDialog(false);
            const newSelectedFiles: IDocumentFileResult[] = includeProtectedDocuments ? [...selectedFiles] : filterNonProtectedDocumentFiles([...selectedFiles], groupItem);

            newSelectedFiles.forEach((selectedFile) => {
                if (selectedFile) { teamRequest.fileRecnos?.push(selectedFile.Recno ?? 0); }
            });

            if (teamRequest.fileRecnos && teamRequest.fileRecnos.length > 0) {
                setProgressState({ loading: true, text: importProgressText });
                dataProvider?.P360?.importFiles(teamRequest).then((res) => {
                    if (res.data) {
                        updateDocumentItemStateHandler(res.data);
                    }
                    setProgressState({ loading: false });
                }).finally(() => {
                    setImportInProgress(false);
                });
            }
            else
                setImportInProgress(false);
        }
    }, [itemImportPath, teamsState, selectedFiles, groupItem, importProgressText, dataProvider?.P360, updateDocumentItemStateHandler]);

    const onClickImport = useCallback(() => {
        if (isAnyProtectedDocumentSelected(selectedFiles, groupItem)) {
            setShowConfirmationDialog(true);
        }
        else
            importFilesHandler(false);
    }, [groupItem, importFilesHandler, selectedFiles]);

    const onConfirmDialogDismiss = useCallback(() => {
        setShowConfirmationDialog(false);
    }, []);

    return <Stack tokens={verticalGapStackTokens}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollablePaneStyles}>
            <Stack tokens={verticalGapStackTokens} style={{ marginLeft: '10px' }}>
                <div style={{ marginTop: '-5px' }}><ProgressControl visible={progressState.loading} label={progressState.text ?? ''} /></div>
                <div aria-disabled={importInProgress} className={importInProgress ? classNames.isDisabled : ''}>
                    <Searchbox {...props} includeClosedCases={true} enumSearchCriteria={EnumSearchCriteria.All} getDocumentsHandler={getDocumentsHandler} searchBoxWidth={330} />
                    <ItemHoverCard enumHoverCard={EnumHoverCard.Case} caseResult={selectedCase} />
                    {
                        groupItem.length > 0 &&
                        <>
                            <Stack horizontal horizontalAlign="start">
                                <span><Link onClick={onClickSelectAll} underline>{selectAllGroup === false ? t('Select all') : t('Unselect all')}</Link></span>
                                <span style={{ paddingLeft: '12px' }}><Link onClick={onClickExpandCollapseAll} underline>{allGroupCollapsed === true ? t('Expand all') : t('Collapse all')}</Link></span>
                            </Stack>
                            <DetailsList
                                styles={detailListStyles}
                                compact={true}
                                columns={_columns}
                                items={documentsState}
                                groups={groupItem}
                                groupProps={{ showEmptyGroups: true, onRenderHeader: onRenderGroupHeader }}
                                layoutMode={DetailsListLayoutMode.justified}
                                onRenderItemColumn={renderItemColumn}
                                selectionPreservedOnEmptyClick={true}
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                isHeaderVisible={false}
                                selectionMode={SelectionMode.multiple}
                                selection={selection}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderRow={onDetailRenderRow}
                                checkButtonAriaLabel="Row checkbox"
                                checkboxVisibility={CheckboxVisibility.always} />
                            <MessageBar messageBarIconProps={messageBarIconProps} style={{ display: 'flex' }} isMultiline={true}>
                                <div>{selectedFiles.length} {t('File selected')} <Link onClick={onClickClearAll} underline>{t('Clear')}</Link></div>
                                <div>{t('Files will be imported on')} {itemImportPath?.fileName}</div>
                            </MessageBar>
                            <span>
                                <PrimaryButton disabled={selectedFiles.length > 0 && progressState.loading} styles={buttonStyles} onClick={onClickImport}>
                                    {t('Import')}
                                </PrimaryButton>
                                <DefaultButton disabled={importInProgress} onClick={closePanelFileImport}>{t('Close')}</DefaultButton>
                            </span>
                            <ConfirmDialog showDialog={showConfirmationDialog} confirmationHandler={importFilesHandler} onDismiss={onConfirmDialogDismiss} />
                        </>
                    }
                </div>
            </Stack>
        </ScrollablePane>
    </Stack >;
};

function createGroups(documents: IDocumentResult[], documentCase: ICaseResult | undefined, p360Url: string): IGroup[] {
    const documentGroups: IGroup[] = [];
    let startIndex = 0;
    for (let i = 0; i < documents.length; i++) {
        if (documentCase)
            documents[i].Case = documentCase;

        documents[i].URL = CombineUrl(p360Url, documents[i].URL);
        documentGroups.push(
            {
                key: `group${i}`,
                name: `${documents[i].DocumentNumber} - ${documents[i].Title}`,
                isCollapsed: true,
                startIndex: startIndex,
                count: documents[i].Files.length,
                level: 0,
                data: documents[i]
            });
        startIndex += documents[i].Files.length;
    }
    return documentGroups;
}

function createGroupItems(groups: IGroup[]): IDocumentFileResult[] {
    const documentFileGroupItems: IDocumentFileResult[] = [];
    let fileCount = 1;

    for (const group of groups) {
        if (group.data) {
            const documentItem = group.data as IDocumentResult;
            for (const documentFile of documentItem.Files) {
                documentFileGroupItems.push({
                    ...documentFile,
                    key: `file${fileCount} `,
                    Format: documentFile.Format?.replace('.', ''),
                    IconName: FileType.getfileType(documentFile.Format?.replace('.', '') ?? "").url,
                    Document: `${documentItem.DocumentNumber} - ${documentItem.Title} `
                });
                fileCount++;
            }
        }
    }
    return documentFileGroupItems;
}

function isAnyProtectedDocumentSelected(selectedFiles: IDocumentFileResult[], documentGroups: IGroup[]): boolean {
    for (const documentFile of selectedFiles) {
        const document = findDocumentUsingFile(documentGroups, documentFile);
        if (isDocumentProtected(document))
            return true;
    }
    return false;
}

function filterNonProtectedDocumentFiles(selectedFiles: IDocumentFileResult[], documentGroups: IGroup[]): IDocumentFileResult[] {
    let filteredFiles: IDocumentFileResult[] = [];
    for (const documentFile of selectedFiles) {
        const document = findDocumentUsingFile(documentGroups, documentFile);
        if (!isDocumentProtected(document))
            filteredFiles = filteredFiles.concat(documentFile);
    }

    return filteredFiles;
}

function findDocumentUsingFile(documentGroups: IGroup[], documentFile: IDocumentFileResult): IDocumentResult {
    return documentGroups.find((group) => {
        const document = group.data as IDocumentResult;
        const findFile = (f: IDocumentFileResult) => f.Recno === documentFile.Recno;
        return document.Files.some(findFile);
    })?.data as IDocumentResult;
}

function isDocumentProtected(document: IDocumentResult): boolean {
    return document.CustomFields?.find(c => c.Name === "IsDocumentProtected")?.Value.toUpperCase() === "TRUE";
}

export default ImportView;