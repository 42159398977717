import { FC, useEffect } from "react";

interface IRedirectProps {
    url: string
}

const Redirect: FC<IRedirectProps> = (props) => {
    useEffect(() => {
        window.location.replace(props.url);
    }, [props.url]);
    
    return <></>;
};

export default Redirect;
