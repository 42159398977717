import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, IDialogProps } from "@fluentui/react";
import { FC, useCallback, useMemo } from "react";
import { t } from "i18next";
import { useP360 } from "../../../context/P360Context";
import { Constants } from "../../../helpers/Constants";

interface ConfirmDialogProps extends IDialogProps {
    showDialog: boolean;
    confirmationHandler(include: boolean): void
}
const modalPropsStyles = { main: { maxWidth: 450 } };

const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
    const { showDialog, confirmationHandler, onDismiss } = props;
    const { p360State } = useP360();
    const restrictedImportBlocked = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DenyRestricted);

    const dialogContentProps = {
        type: DialogType.normal,
        title: t('File Import Confirmation'),
        closeButtonAriaLabel: t('Close'),
        subText: restrictedImportBlocked ?
            t('You have selected one or more restricted documents. These documents will not be included in the import. Do you want to continue?') :
            t('You are about to import restricted document(s) to Teams, do you want to include them in the import?')
    };
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined
        }), []);

    const primaryButtonText = restrictedImportBlocked ? t('Continue') : t('Include');
    const primaryButtonHandler = useCallback(() => confirmationHandler(!restrictedImportBlocked), [confirmationHandler, restrictedImportBlocked]);
    const secondaryButtonText = restrictedImportBlocked ? t('Cancel') : t('Exclude');
    const secondaryButtonHandler = useCallback(() => {
        if (restrictedImportBlocked) {
            onDismiss && onDismiss();
        } else {
            confirmationHandler(false);
        }
    }, [confirmationHandler, onDismiss, restrictedImportBlocked]);
    return <Dialog
        hidden={!showDialog}
        onDismiss={onDismiss}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
    >
        <DialogFooter>
            <PrimaryButton onClick={primaryButtonHandler} text={primaryButtonText} />
            <DefaultButton onClick={secondaryButtonHandler} text={secondaryButtonText} />
        </DialogFooter>
    </Dialog>;
};

export default ConfirmDialog;