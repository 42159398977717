import { FC } from 'react';
import { Link, TooltipHost } from '@fluentui/react';
import { IFileListItem } from '../../model/model';
import { iconClass, tooltipHostStyles } from '../../styles/MergeStyleSets';
import ReadOnlyIcon from '../../images/readonlylock.svg';
import CheckoutIcon from '../../images/checkout.svg';
import NotSupportedFileIcon from '../../images/notSupportedFileIcon.svg';
import { useId } from '@fluentui/react-hooks';
import { t } from "i18next";
import { Constants } from '../../helpers/Constants';
import { useP360 } from '../../context/P360Context';

interface IFileNameFieldProps {
    item: IFileListItem,
    fieldContent: string,
    navigateToFolder(folderId: string): void
}

const FileNameField: FC<IFileNameFieldProps> = (props) => {
    const { item, fieldContent, navigateToFolder } = props;
    const tooltipId = useId('tooltip');
    const { p360State } = useP360();

    const calloutProps = { gapSpace: 0 };
    const readonlyTooltipText = `${t('The file is read-only because the 360° document status is')} ${item.documentStatus}`;
    const checkoutTooltipText = t(`The file cannot be edited because it is checked out in 360°`);
    const notSupportedFileTypeTooltipText = t(`This file format is not supported in 360°`);
    const notSupportedSharepointAppUploadedFilesTooltipText = t(`This file does not have a valid value in the Modified by column. Please rename the file in Teams if you want to archive it to 360°`);
    const notSupportedSharepointAppUploadedFoldersTooltipText = t(`This folder does not have a valid value in the Modified by column. Please rename the folder in Teams if you want to connect it to a 360° case`);

    if (item.isFolder) {
        return <>
            <Link onClick={() => { navigateToFolder(item.fileId as string); }}>
                {fieldContent}
            </Link>
            {!item?.modifiedBy && <TooltipHost content={notSupportedSharepointAppUploadedFoldersTooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={NotSupportedFileIcon} className={iconClass} alt={notSupportedSharepointAppUploadedFoldersTooltipText} />
            </TooltipHost>}
        </>;
    }

    if (item.isReadonly) {
        return <span>
            {fieldContent}
            <TooltipHost content={readonlyTooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={ReadOnlyIcon} className={iconClass} alt={readonlyTooltipText} />
            </TooltipHost>
        </span>;
    }

    if (item.IsCheckedout) {
        return <span>
            {fieldContent}
            <TooltipHost content={checkoutTooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={CheckoutIcon} className={iconClass} alt={checkoutTooltipText} />
            </TooltipHost>
        </span>;
    }

    if (item.isNotSupported) {
        return <span>
            {fieldContent}
            <TooltipHost content={notSupportedFileTypeTooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={NotSupportedFileIcon} className={iconClass} alt={notSupportedFileTypeTooltipText} />
            </TooltipHost>
        </span>;
    }

    if (!item?.modifiedBy) {
        return <span>
            {fieldContent}
            <TooltipHost content={notSupportedSharepointAppUploadedFilesTooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={NotSupportedFileIcon} className={iconClass} alt={notSupportedSharepointAppUploadedFilesTooltipText} />
            </TooltipHost>
        </span>;
    }

    const hasTruncateCapability = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.TruncateLongFileNames);
    const maxLength = hasTruncateCapability ? Constants.FileNameMaxLength : Constants.FileNameMaxLengthWithoutBLTruncate;
    if (!item.documentConnection && !item.isConnectedTo360 && item.fileName && item.fileName.length > maxLength) {
        const longFileNameTooltipText = hasTruncateCapability ? t('File name is too long, and it will be truncated in 360° once archived') : t('File cannot be archived to 360° because the file name is too long. Please shorten the file name (max 200 characters) to enable archiving to 360°.');
        return <span>
            {fieldContent}
            <TooltipHost content={longFileNameTooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
                <img src={NotSupportedFileIcon} className={iconClass} alt={longFileNameTooltipText} />
            </TooltipHost>
        </span>;
    }

    return <>{fieldContent}</>;
};

export default FileNameField;