import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { FC, useCallback } from "react";
import { useFiles } from "../../context/FilesContext";
import { IFileListItem } from "../../model/model";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { useP360 } from "../../context/P360Context";
import { CombineUrl } from "../../helpers/UrlHelper";
import { useTeams } from "../../context/TeamsContext";
import { ErrorType, useApiErrorHandler } from "../../context/ApiErrorHandler";
import ProgressControl from "../ProgressControl";

interface ISyncConflictControlProps extends ICommonProps {
    item: IFileListItem;
    style?: React.CSSProperties
}

const SyncConflictControl: FC<ISyncConflictControlProps> = (props) => {
    const { removeSyncConflict } = useFiles();
    const { p360State } = useP360();
    const { teamsState } = useTeams();
    const { addError } = useApiErrorHandler();

    const [isDialogOpen, { setTrue: showDialog, setFalse: hideDialog }] = useBoolean(false);
    const [isSyncing, { setTrue: startSync, setFalse: stopSync }] = useBoolean(false);
    const titleId = useId('sync_conflict_title');

    const syncFileFrom360 = useCallback(async () => {
        startSync();
        props.dataProvider?.P360.syncFileWithTeams({ accessToken: await teamsState.getAccessToken(), fileRecno: props.item.fileRecno as number })
            .then((res) => {
                if (res.data?.IsSuccessful) {
                    removeSyncConflict(props.item);
                } else {
                    addError({ message: res.data?.ErrorMessage } as ErrorType);
                }
            })
            .catch((err) => { addError(err); })
            .finally(() => {
                stopSync();
                hideDialog();
            });
    }, [addError, hideDialog, props.dataProvider?.P360, props.item, removeSyncConflict, startSync, stopSync, teamsState]);

    const linkToFileIn360 = CombineUrl(p360State.p360Url, "GetFile.aspx", `?fileId=${props.item.fileRecno?.toString() as string}`);

    if (!props.item.fileRecno) { return <></>; }

    return <span>
        <DefaultButton text={t('Resolve conflict')} onClick={showDialog} style={props.style} />
        <Dialog
            modalProps={{
                titleAriaId: titleId,
                isBlocking: true,
            }}
            dialogContentProps={{
                title: t('Resolve synchronization conflict'),
                subText: t('File was modified in 360°, but an error occurred while synchronizing the file to Teams. File will not be synchronized between the systems until the conflict is resolved.')
            }}
            maxWidth={650}
            hidden={!isDialogOpen && !isSyncing}
            onDismiss={hideDialog}>
            <div>
                <p>
                    {t('Conflict must be resolved by synchronizing the file from 360° to Teams. Before doing that you should make sure that no information is lost.')}
                </p>
                <p>
                    {t('Before resolving the conflict, consider following steps:')}
                </p>
                <ul>
                    <li>{t('Compare the files in the two systems and see if the file in Teams has some information that is missing from 360°')}</li>
                    <ul>
                        <li><a href={linkToFileIn360} target="_blank" rel="noreferrer">{t('Download a copy of the file from 360°')}</a></li>
                        {props.item.spFileDownloadUrl && <li><a href={props.item.spFileDownloadUrl} target="_blank" rel="noreferrer">{t('Download a copy of the file from Teams')}</a></li>}
                    </ul>
                    <li>{t('Take a backup of the file in Teams (or rely on the file version history)')}</li>
                    <li>{t('Update the missing information to the file in 360° before resolving the conflict, OR do the needed changes after resolving the conflict')}</li>
                </ul>
                <p>
                    {t('Resolve conflict by clicking the button below.')} <strong>{t('Note that this will overwrite the file in Teams with the latest version of the file in 360°.')} </strong>
                    {t('Before clicking the button please ensure that the file is not checked out or opened for editing.')}
                </p>
                {props.item.IsCheckedout && <p style={{ color: 'red' }}>{t("Conflict cannot be resolved because the file is checked out. Please ensure the file is checked in before attempting to resolve the conflict.")}</p>}
            </div>
            <ProgressControl visible={isSyncing} label={t("Synchronizing...")} />
            <DialogFooter>
                <PrimaryButton onClick={syncFileFrom360} text={t('Synchronize file from 360°')} disabled={isSyncing || props.item.IsCheckedout} />
                <DefaultButton onClick={hideDialog} text={t('Cancel')} disabled={isSyncing} />
            </DialogFooter>
        </Dialog>
    </span>;
};

export default SyncConflictControl;