import { FC } from 'react';
import { iconClass } from '../../styles/MergeStyleSets';
import { Link, Shimmer, Stack } from '@fluentui/react';
import { IFileListItem } from '../../model/model';
import CaseEntityIcon from '../../images/caseentity.svg';
import { CombineUrl } from '../../helpers/UrlHelper';
import { t } from "i18next";
import { Features } from '../../config/Features';

interface ICaseConnectionLinkProps {
    item: IFileListItem,
    p360url: string,
    loading?: boolean
}

const CaseConnectionLink: FC<ICaseConnectionLinkProps> = (props) => {
    const { item, loading, p360url } = props;

    if (Features.UseNewLoading && !item.archivingStatusChecked && loading) {
        return <Shimmer aria-label={t('Loading...')} />;
    }

    if (item.caseConnection) {
        return <Stack horizontal horizontalAlign="start">
            <span style={{ display: 'flex', padding: '3px 0' }}>
                <img src={CaseEntityIcon} className={iconClass} alt={t('Case')} />
            </span>
            <span>
                <Link target="_blank" href={CombineUrl(p360url, item.caseConnection)} underline={true}>{item.caseTitle}</Link>
            </span>
        </Stack>;
    }

    if (item.isConnectedTo360 && !item.documentConnection) {
        const accessDeniedLabel = t('Access Denied');
        return <span style={{ color: 'red' }}>{accessDeniedLabel}</span>;
    }

    return null;
};

export default CaseConnectionLink;