import { app } from "@microsoft/teams-js";
import { FakeMsTeamsProvider } from "./FakeMsTeamsProvider";
import { FakeP360Provider } from "./FakeP360Provider";
import { IMsTeamsProvider } from "./IMsTeamsProvider";
import { IP360Provider } from "./IP360Provider";
import { MsTeamsProvider } from "./MsTeamsProvider";
import { P360Provider } from "./P360Provider";

export enum AuthStatus {
    Loading = "loading",
    Authorised = "authorised",
    NotAuthorised = "notAuthorised"
}

export interface IAuthResult {
    AuthStatus: AuthStatus,
    AccessToken: string,
    Message: string
}

export class DataProvider {
    public MsTeams: IMsTeamsProvider;
    public P360: IP360Provider;

    constructor(msTeamsProvider?: IMsTeamsProvider, p360Provider?: IP360Provider) {
        const useFakes = process.env.REACT_APP_USE_FAKES === 'true';

        this.MsTeams = msTeamsProvider ?? useFakes ? new FakeMsTeamsProvider() : new MsTeamsProvider();
        this.P360 = p360Provider ?? useFakes ? new FakeP360Provider() : new P360Provider();
        
        // Loading fakes should be done only when they are used, but could not get this working. There are other methods like lazy loading, we should look into this later. 
        // try: React.lazy(() => import('./FakeMsTeamsProvider')), maybe React.suspense is needed?	
        // if (useFakes) {
        //     import("./FakeMsTeamsProvider").then(({ FakeMsTeamsProvider }) => { this.MsTeams = new FakeMsTeamsProvider(); });
        //     import("./FakeP360Provider").then(({ FakeP360Provider }) => { this.P360 = new FakeP360Provider(); });
        // }
    }

    public getApiBaseUrl(): Promise<string> {
        return new Promise<string>((resolve) => {
            this.MsTeams.getSettings().then(settings => {
                resolve(new URLSearchParams(settings.contentUrl != null ? settings.contentUrl.split('?')[1] : "").get("p360url") as string);
            });
        });
    }

    public getConfigurationUrl(): Promise<string> {
        return new Promise<string>((resolve) => {
            const urlParams = new URLSearchParams(window.document.location.search);
            resolve(urlParams.has("default360Url") === true ? urlParams.get("default360Url") as string : "");
        });
    }

    public authenticate(): Promise<IAuthResult> {
        return new Promise<IAuthResult>((resolve) => {
            this.MsTeams.getAuthToken().then(token => {
                resolve({ AuthStatus: AuthStatus.Authorised, AccessToken: token, Message: "" });
            }).catch(reason => {
                this.MsTeams.notifyFailure({ reason: app.FailedReason.AuthFailed, message: AuthStatus.NotAuthorised });
                resolve({ AuthStatus: AuthStatus.NotAuthorised, AccessToken: "", Message: reason });
            });
        });
    }
}