import { ThemeProvider } from "@fluentui/react";
import i18n from '../i18n';

function Support(): JSX.Element {
    return (
        <ThemeProvider style={{ margin: '20px' }}>
            <h1>Teams integration support information</h1>
            <p>For user support, check the following helpful links:</p>
            <ul>
                <li><a href={i18n.t("HelpPageUrl", { p360version: "5.14" })} title="help" target="_blank" rel="noreferrer">Help pages</a></li>
                <li><a href="https://help.360online.com/360Misc/QnA%20Integration%20with%20MS%20Teams.pdf" title="Q&amp;A" target="_blank" rel="noreferrer">Q&amp;A</a></li>
            </ul>
            <p>If you ran into an issue and think there's a bug, you can create a ticket through <a href="https://tieto.service-now.com/tsc" title="Tietoevry ServiceNow" target="_blank" rel="noreferrer">Tietoevry ServiceNow</a></p>
            <p>If you want information on how to purchase 360&deg; or Teams integration, you may contact <a href="mailto:anna.gertz@tietoevry.com" target="_blank" rel="noreferrer">Anna Gertz</a></p>
        </ThemeProvider>
    );
}

export default Support;