import { app } from "@microsoft/teams-js";
import { Case, IClassCode, ISharepointDocumentItem, ISharepointFileResponse, ITeamRequest } from "../model/model";
import { FileType } from "./FileType";
import { FormatDateTime } from "./FormatDateTime";

export function MapTeamsContextToITeamRequest(context: app.Context | undefined): ITeamRequest | undefined {
    if (context === undefined || context === null) { return undefined; }
    return {
        teamId: context.team?.groupId as string ?? context.channel?.ownerGroupId as string,
        teamName: context.team?.displayName as string,
        teamSiteDomain: context.sharePointSite?.teamSiteDomain as string,
        teamSitePath: context.sharePointSite?.teamSitePath as string,
        teamSiteUrl: context.sharePointSite?.teamSiteUrl as string,
        channel: {
            channelId: context.channel?.id as string,
            channelName: context.channel?.displayName as string,
            channelType: context.channel?.membershipType as string,
            channelRelativeUrl: context.channel?.relativeUrl as string
        }
    };
}

export function MapISharePointFileResponseToISharepointDocumentItem(item: ISharepointFileResponse, key?: string | number): ISharepointDocumentItem {
    const isFolder = item.Folder != null;
    const fileType = FileType.getfileType(item.File?.Name, isFolder);
    return {
        key: key,
        fileId: item.Id,
        fileDriveId: item.DriveId,
        isFolder: isFolder,
        fileName: formatFileName(item.File.Name),
        iconName: fileType.url,
        fileType: fileType.docType,
        modified: FormatDateTime.convert(item.ModifiedDateTime),
        modifiedBy: item.LastModifiedBy?.User?.DisplayName,
        caseNumber: item.Case?.CaseNumber,
        documentNumber: item.Document?.DocumentNumber,
        caseConnection: item.Case?.LinkTo360,
        documentConnection: item.Document?.LinkTo360,
        caseTitle: formatCaseTitle(item.Case),
        documentTitle: item.Document?.DocumentNumber + ' - ' + item.Document?.Title,
        documentStatus: item.Document?.DocumentStatus,
        archiveStatus: item.Document?.ArchiveStatus,
        packageType: item.Package?.Type,
        archiveCount: item.ArchiveCount,
        totalCount: item.TotalCount,
        isReadonly: isReadonly(item), IsCheckedout: item.IsCheckedout,
        isNotSupported: item.IsNotSupported, isConnectedTo360: item.IsConnectedTo360,
        isSyncFailed: item.IsSyncFailed,
        fileRecno: item.Recno,
        spFileDownloadUrl: item.ContentUrl
    };
}

export function isReadonly(item: ISharepointFileResponse): boolean {
    return item.Document?.IsDocumentReadOnly != null ? item.Document.IsDocumentReadOnly : item.IsReadonly === true;
}

export function formatCaseTitle(caseInfo?: Case): string {
    let result = caseInfo?.CaseNumber?.trim() ?? "";
    result += result && caseInfo?.Title ? ` - ${caseInfo?.Title?.trim()}` : caseInfo?.Title?.trim() ?? "";
    return result;
}

export function formatVersionForHelpUrl(p360Version?: string): string {
    const version = p360Version?.replace("Version", "").trim();
    return `${version?.split('.')[0]}.${version?.split('.')[1]}`;
}

export function formatFileName(fileName: string): string {
    if (fileName.includes('.one') || fileName.includes('.onetoc')) {
        return FileType.removeExtensionFromFileName(fileName);
    }
    return fileName;
}

export function sortData(a: IClassCode, b: IClassCode) {
    if (a.Code < b.Code) {
        return -1;
    }
    if (a.Code > b.Code) {
        return 1;
    }
    return 0;
}