import { FontIcon, Label, mergeStyles, Stack, ThemeProvider } from "@fluentui/react";
import { FC } from "react";

export interface IConsentErrorProps {
    searchParams: URLSearchParams
}

const ConsentError: FC<IConsentErrorProps> = (props: IConsentErrorProps) => {
    const iconClass = mergeStyles({
        fontSize: 50,
        height: 50,
        width: 50,
        margin: '15px 25px',
        color: "red"
    });

    return <ThemeProvider>
        <Stack horizontal verticalAlign="start" horizontalAlign="center" style={{margin: "30px"}}>
            <FontIcon iconName="ErrorBadge" className={iconClass} />
            <Stack>
                <h1>Error occurred during the consent</h1>
                <Label>Reason:</Label><span>{props.searchParams.get("error")}</span><br />
                <Label>Description:</Label><span>{props.searchParams.get("error_description")}</span><br />
                <Label>Error URI:</Label><span>{props.searchParams.get("error_uri")}</span>
            </Stack>
        </Stack>

    </ThemeProvider>;
};

export default ConsentError;