import { FC } from 'react';
import { DirectionalHint, HoverCard, HoverCardType, IPlainCardProps, Link, Separator, Stack } from '@fluentui/react';
import CaseEntityIcon from '../../images/caseentity.svg';
import DocumentEntityIcon from '../../images/documententity.svg';
import { t } from "i18next";
import { EnumHoverCard, ICaseResult, IDocumentResult } from '../../model/model';
import { classNames, hoverCardDetail, hoverCardFooter, hoverCardGapToken, hoverCardHeader, iconClass, itemAlignmentsStackStyles1, itemAlignmentsStackTokens, itemStylesT, verticalGapStackTokens } from '../../styles/MergeStyleSets';
import i18n from '../../i18n';

interface IItemHoverCardProps {
    caseResult?: ICaseResult,
    documentResult?: IDocumentResult,
    enumHoverCard: EnumHoverCard
}

const onRenderPlainCardForDocument = (item: IDocumentResult) => {
    const document = { responsiblePersonName: '', category: '' };
    if (item.ResponsiblePerson)
        document.responsiblePersonName = item.ResponsiblePerson.Name;
    if (item.Category)
        document.category = item.Category.Code;
    return (
        <Stack>
            <Stack horizontal horizontalAlign="start" styles={hoverCardHeader} tokens={itemAlignmentsStackTokens}>
                <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles1}>
                    <span>
                        <img src={DocumentEntityIcon} style={itemStylesT} alt={i18n.t('Document')} />
                    </span>
                </Stack>
                <Stack horizontal horizontalAlign="start">
                    <Stack tokens={itemAlignmentsStackTokens}>
                        <Stack.Item align="auto">
                            <span className={classNames.container}>
                                <Link target="_blank" href={item.URL} underline>{item.Title}</Link>
                            </span>
                        </Stack.Item>
                        <Stack.Item align="auto">
                            <span>{i18n.t('Document')}: {item.DocumentNumber}</span>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
            <Separator />
            <Stack horizontal horizontalAlign="start" style={{ marginTop: '-25px' }} styles={hoverCardDetail} tokens={verticalGapStackTokens}>
                <Stack tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Document status')}</span>
                        <span>{item.StatusDescription}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Responsible Unit')}</span>
                        <span>{item.ResponsibleEnterpriseName}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Access code')}</span>
                        <span>{item.AccessCodeCode}</span>
                    </Stack>
                </Stack>
                <Stack tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Document category')}</span>
                        <span>{document.category}</span>
                    </Stack>
                    <Stack style={{ marginTop: '15px' }}>
                        <span>{i18n.t('Responsible person')}</span>
                        <span>{document.responsiblePersonName}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Access group')}</span>
                        <span>{item.AccessGroup}</span>
                    </Stack>
                </Stack>
            </Stack>
            <Separator />
            {item.Case && <Stack horizontal horizontalAlign="start" style={{ marginTop: '-25px', height: '90px' }} styles={hoverCardFooter} tokens={verticalGapStackTokens}>
                <Stack tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Case')}</span>
                        <span><Link target="_blank" href={item.Case?.URL} underline>{item.Case?.CaseNumber}-{item.Case?.Title}</Link></span>
                    </Stack>
                </Stack>
            </Stack>}
        </Stack>
    );
};

const onRenderPlainCardForCase = (item: ICaseResult) => {
    const lastChangedDate = new Date(item.LastChangedDate);
    const caseInfo = { responsiblePersonName: '' };
    if (item.ResponsiblePerson)
        caseInfo.responsiblePersonName = item.ResponsiblePerson.Name;
    return (
        <Stack>
            <Stack horizontal horizontalAlign="start" styles={hoverCardHeader} tokens={itemAlignmentsStackTokens}>
                <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles1}>
                    <span>
                        <img src={CaseEntityIcon} style={itemStylesT} alt={i18n.t('Case')} />
                    </span>
                </Stack>
                <Stack horizontal horizontalAlign="start">
                    <Stack tokens={itemAlignmentsStackTokens}>
                        <Stack.Item align="auto">
                            <span className={classNames.container}>
                                <Link target="_blank" href={item.URL} underline>{item.Title}</Link>
                            </span>
                        </Stack.Item>
                        <Stack.Item align="auto">
                            <span>{i18n.t('Case')}: {item.CaseNumber}</span>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
            <Separator />
            <Stack horizontal horizontalAlign="start" style={{ marginTop: '-25px' }} styles={hoverCardDetail} tokens={verticalGapStackTokens}>
                <Stack tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Case Status')}</span>
                        <span>{item.Status}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Responsible Unit')}</span>
                        <span>{item.ResponsibleEnterpriseName}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Access code')}</span>
                        <span>{item.AccessCodeCode}</span>
                    </Stack>
                </Stack>
                <Stack tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Case type')}</span>
                        <span>{item.CaseTypeCode}</span>
                    </Stack>
                    <Stack style={{ marginTop: '15px' }}>
                        <span>{i18n.t('Responsible person')}</span>
                        <span>{caseInfo.responsiblePersonName}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Access group')}</span>
                        <span>{item.AccessGroup}</span>
                    </Stack>
                </Stack>
            </Stack>
            <Separator />
            <Stack horizontal horizontalAlign="start" style={{ marginTop: '-25px' }} styles={hoverCardFooter} tokens={verticalGapStackTokens}>
                <Stack tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Documents')}</span>
                        <span>{item.Documents.length}</span>
                    </Stack>
                    <Stack>
                        <span>{i18n.t('Internal case contacts')}</span>
                        <span>{item.Contacts.map(c => c.ContactName).join(', ')}</span>
                    </Stack>
                </Stack>
                <Stack style={{ marginLeft: '-20px' }} tokens={hoverCardGapToken}>
                    <Stack>
                        <span>{i18n.t('Last activity')}</span>
                        <span>{lastChangedDate.toLocaleString()}</span>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const ItemHoverCard: FC<IItemHoverCardProps> = (props) => {
    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: props.enumHoverCard === EnumHoverCard.Case ? onRenderPlainCardForCase : onRenderPlainCardForDocument,
        directionalHint: DirectionalHint.leftTopEdge,
        renderData: props.enumHoverCard === EnumHoverCard.Case ? props.caseResult : props.documentResult
    };

    if (props.enumHoverCard === EnumHoverCard.Case && props.caseResult) {
        return <HoverCard plainCardProps={plainCardProps} type={HoverCardType.plain}>
            <Stack horizontal horizontalAlign="start">
                <span style={{ display: 'flex', padding: '3px 0' }}>
                    <img src={CaseEntityIcon} className={iconClass} alt={t('Case')} />
                </span>
                <span className={classNames.overflowTextDots}>
                    <Link target="_blank" href={props.caseResult?.URL}>{props.caseResult?.CaseNumber + '-' + props.caseResult?.Title}</Link>
                </span>
            </Stack>
        </HoverCard>;
    }
    else if (props.enumHoverCard === EnumHoverCard.Document && props.documentResult) {
        return <HoverCard plainCardProps={plainCardProps} type={HoverCardType.plain}>
            <Link target="_blank" href={props.documentResult?.URL}>
                {props.documentResult?.DocumentNumber} - {props.documentResult?.Title}
            </Link>
        </HoverCard>;
    }
    else
        return <></>;
};

export default ItemHoverCard;