import { DefaultButton, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { EnumSearchCriteria } from '../../model/model';
import { ICommonProps } from '../ICommonProps';
import { t } from "i18next";

interface ISearchOptionButtonProps extends ICommonProps {
    enumSearchCriteria: EnumSearchCriteria,
    buttonText?: string,
    enableDocumentSearchFeature?: boolean,
    caseSearchHandler(): void,
    documentSearchHandler?: () => void
}

const addCaseSearchItem = (itemText: string, caseSearchHandler: () => void): IContextualMenuItem[] => {
    return [
        {
            key: 'case',
            text: itemText,
            onClick: () => caseSearchHandler()
        }
    ];
};

const addDocumentSearchItem = (itemText: string, items: IContextualMenuItem[], documentSearchHandler?: () => void): IContextualMenuItem[] => {
    return [
        ...items,
        {
            key: 'document',
            text: itemText,
            onClick: () => {
                if (documentSearchHandler)
                    documentSearchHandler();

            }
        }
    ];
};

const SearchOptionButton: FC<ISearchOptionButtonProps> = (props) => {
    const [contextMenuItems, setContextMenuItems] = useState<IContextualMenuProps>({ items: [] });
    const { enumSearchCriteria, buttonText, enableDocumentSearchFeature, caseSearchHandler, documentSearchHandler } = props;
    useEffect(() => {
        const menuItems: IContextualMenuProps = { items: addCaseSearchItem(t('Case'), caseSearchHandler) };
        if (enableDocumentSearchFeature)
            menuItems.items = addDocumentSearchItem(t('Document'), menuItems.items, documentSearchHandler);
        setContextMenuItems(menuItems);
    }, [enableDocumentSearchFeature, caseSearchHandler, documentSearchHandler]);

    if (enumSearchCriteria === EnumSearchCriteria.All) {
        return <DefaultButton
            text={buttonText}
            menuProps={contextMenuItems}
            allowDisabledFocus
            style={{ width: '100px' }}
        />;
    }

    return <DefaultButton
        text={buttonText}
        allowDisabledFocus
        style={{ width: '40px' }}
    />;
};

export default SearchOptionButton;