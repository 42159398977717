import { IRenderFunction, IPanelProps, Stack, Panel, PanelType } from "@fluentui/react";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFiles } from "../../context/FilesContext";
import i18n from "../../i18n";
import DocumentEntityIcon from '../../images/documententity.svg';
import { IFileListItem, ISharepointFileResponse } from "../../model/model";
import { DataProvider } from "../../providers/DataProvider";
import { importPanelHeaderStyle, itemAlignmentsStackTokens, itemAlignmentsStackStyles1, itemStylesT, itemAlignmentsStackStyles2, stackItemDocumentTitleStyles, stackItemDocumentSubTitleStyles, verticalGapStackTokens, importPanelSubheaderAlignmentStyles, viewSubHeaderContentAlignmentStyles } from "../../styles/MergeStyleSets";
import ImportView from "../panel/fileimportpanel/ImportView";

interface IImportPanelContainerProps extends IPanelProps {
    dataProvider: DataProvider;
    selectedFiles?: IFileListItem[];
}

const onRenderNavigationContent: IRenderFunction<IPanelProps> = (props, defaultRender) => (
    <>
        <Stack horizontal horizontalAlign="start" styles={importPanelHeaderStyle} tokens={itemAlignmentsStackTokens}>
            <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles1}>
                <span>
                    <img src={DocumentEntityIcon} style={itemStylesT} alt={i18n.t('Document')} />
                </span>
            </Stack>
            <Stack horizontal horizontalAlign="start" styles={itemAlignmentsStackStyles2}>
                <Stack tokens={itemAlignmentsStackTokens}>
                    <Stack.Item align="auto" styles={stackItemDocumentTitleStyles}>
                        <span>{i18n.t('Import files from 360')}</span>
                    </Stack.Item>
                    <Stack.Item align="auto" styles={stackItemDocumentSubTitleStyles}>
                        <span>{i18n.t('Files will be added to teams')}</span>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
        {
            // This custom navigation still renders the close button (defaultRender).
            // If you don't use defaultRender, be sure to provide some other way to close the panel.
            // eslint-disable-next-line
            defaultRender!(props)
        }
    </>
);

const ImportPanelContainer: FC<IImportPanelContainerProps> = (props) => {
    const { t } = useTranslation();
    const { currentFolder, addImportedFiles } = useFiles();
    const { selectedFiles } = props;
    const importFolder = selectedFiles?.length === 1 && selectedFiles[0].isFolder ? selectedFiles[0] : currentFolder as IFileListItem;
    const updateDocumentItemStateHandler = useCallback((data: ISharepointFileResponse[]) => {
        addImportedFiles(importFolder, data);
        props.onDismiss && props.onDismiss();
    }, [addImportedFiles, importFolder, props]);

    return <Panel type={PanelType.custom} customWidth="500px" isOpen={props.isOpen} onDismiss={props.onDismiss}
        closeButtonAriaLabel={t('Close')} onRenderNavigationContent={onRenderNavigationContent}>
        <Stack tokens={verticalGapStackTokens}>
            <Stack.Item align="auto" styles={importPanelSubheaderAlignmentStyles}>
                <span style={viewSubHeaderContentAlignmentStyles}>{t('File Importer')}</span>
            </Stack.Item>
        </Stack>
        <ImportView dataProvider={props.dataProvider}
            itemImportPath={importFolder}
            closePanelFileImport={props.onDismiss as () => void}
            updateDocumentItemStateHandler={updateDocumentItemStateHandler} />
    </Panel>;
};

export default ImportPanelContainer;