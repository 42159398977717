import { FC } from 'react';
import { tooltipHostStyles } from '../../styles/MergeStyleSets';
import { Link, TooltipHost } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

interface ILinkWithTooltipProps {
    tooltipText: string,
    url?: string,
    text: string
}

const LinkWithTooltip: FC<ILinkWithTooltipProps> = (props) => {
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    return <TooltipHost content={props.tooltipText} id={tooltipId} calloutProps={calloutProps} styles={tooltipHostStyles}>
        {
            props.url !== undefined ?
                <Link target="_blank" href={props.url}>{props.text}</Link>
                :
                <span>{props.text}</span>
        }
    </TooltipHost>;
};

export default LinkWithTooltip;