import React, { useEffect, useMemo } from "react";
import ErrorNotification from "../components/error/ErrorNotification";
import { useApiErrorHandler } from "./ApiErrorHandler";
import { IBackendCapabilities } from "../model/model";
import { DataProvider } from "../providers/DataProvider";
import { useTeams } from "./TeamsContext";
import { AxiosError } from "axios";

export interface P360State {
    p360Url: string;
    backendCapabilities?: IBackendCapabilities;
}

interface P360ContextProviderProps {
    children: React.ReactElement;
    dataProvider: DataProvider;
}

const P360Context = React.createContext<{ p360State: P360State } | undefined>(undefined);

function P360ContextProvider(props: P360ContextProviderProps): JSX.Element {
    const { children, dataProvider } = props;
    const [p360State, setP360State] = React.useState<P360State>({
        p360Url: '',
        backendCapabilities: undefined
    });

    const [ready, setReady] = React.useState(false);
    const [authenticationOk, setAuthenticationOk] = React.useState(true);

    const { teamsState } = useTeams();
    const { addError } = useApiErrorHandler();
    const [error, setError] = React.useState<AxiosError>();

    useEffect(() => {
        dataProvider.getApiBaseUrl().then(res => {
            dataProvider.P360.initialize(res, teamsState.getAccessToken);
            
            dataProvider.P360.getConfigurationType().then((cfgRes) => {
                dataProvider.P360.httpClient?.interceptors.response.use((response) => response, (error) => {
                    addError(error);
                    return Promise.reject(error);
                });
                setP360State({ p360Url: res, backendCapabilities: cfgRes.data });
            }).catch((error: AxiosError) => {
                setError(error);
                setAuthenticationOk(false);
            }).finally(() => {
                dataProvider.MsTeams.notifySuccess();
                setReady(true);
            });
        });
    }, [addError, dataProvider, teamsState.getAccessToken]);

    const p360Context = useMemo(() => ({
        p360State: p360State,
        setP360State: setP360State
    }), [p360State]);

    if (!authenticationOk && ready) {
        return (<ErrorNotification errorProp={error} />);
    }

    return (
        <P360Context.Provider value={p360Context}>
            {ready && children}
        </P360Context.Provider>
    );
}

function useP360() {
    const context = React.useContext(P360Context);
    if (context === undefined) {
        throw new Error('useP360 must be used within a P360Context');
    }
    return context;
}

export { P360Context, P360ContextProvider, useP360 };