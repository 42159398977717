import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { initializeIcons } from '@fluentui/react';

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');
const container = document.getElementById('root');
if (container === null) { throw new Error("Root element not found"); }
const root = createRoot(container);
// Strict mode in React 18 breaks some Fluent UI components (such as Tooltips, DetailsList resize on window resize and dynamic render on scroll).
// Check functionality once Fluent UI gets upgrades, and remove condition once issues are gone.
const appNodes = process.env.REACT_APP_USE_STRICT_MODE === "true" ?
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </React.StrictMode>
    :
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>;
root.render(appNodes);