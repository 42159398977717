import { useState, FC, useCallback, FormEvent } from 'react';
import { Stack, ChoiceGroup, IChoiceGroupOption, IRenderFunction, IChoiceGroupOptionProps, TooltipHost } from '@fluentui/react';
import { tooltipHostStyles, verticalGapStackTokens, viewAlignmentStackStyles, viewSubHeaderAlignmentStyles, viewSubHeaderContentAlignmentStyles } from '../../styles/MergeStyleSets';
import ExistingCaseView from './ExistingCaseView';
import NewCaseView from './NewCaseView';
import { EnumCase, EnumUIConfiguration, EnumViewName, ICaseDocumentRequest, ISharepointDocumentItem } from '../../model/model';
import { ICommonPropsArchivePanel } from '../ICommonProps';
import { t } from "i18next";
import { Constants } from '../../helpers/Constants';
import { useP360 } from '../../context/P360Context';
import { useId } from '@fluentui/react-hooks';

interface CaseViewProps extends ICommonPropsArchivePanel {
    selectedFiles?: ISharepointDocumentItem[],
    selectedCase: string,
    caseDocumentData: ICaseDocumentRequest,
    uiConguration: EnumUIConfiguration,
    saveClickHandler(caseData: ICaseDocumentRequest | undefined, archiveOnExistingDocument?: boolean): void,
    continueClickHandler(caseData: ICaseDocumentRequest | undefined, viewName: EnumViewName): void,
    closeClickHandler(): void
}

const CaseView: FC<CaseViewProps> = (props) => {
    const { p360State } = useP360();
    const tooltipId = useId('tooltip');
    const archiveOnExistingDocumentEnabled = props.isFileArchiveAction === true &&
        p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.ArchiveOnExistingDocument) === true &&
        p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DocumentsSearch) === true;

    const disallowCaseCreation = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DisallowCaseCreation) === true;

    const { selectedCase, closeClickHandler } = props;

    const [selectedExistingCaseKey, setSelectedExistingCaseKey] = useState<EnumCase>(() => {
        if (typeof props.caseDocumentData?.selectionNewOrExisting != 'undefined' && props.caseDocumentData?.selectionNewOrExisting)
            return props.caseDocumentData?.selectionNewOrExisting;
        else
            return EnumCase.ExistingCase;
    });

    const newCaseOptionRender: IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps> = useCallback((props, render) => {
        return (
            <TooltipHost content={t('User does not have permission to create a new case')} id={tooltipId} calloutProps={{ gapSpace: 0, hidden: !disallowCaseCreation }} styles={tooltipHostStyles}>
                {
                    render !== undefined ?
                        render(props) : null
                }
            </TooltipHost>
        );
    }, [tooltipId, disallowCaseCreation]);

    const options: IChoiceGroupOption[] = [
        { key: EnumCase.NewCase.toString(), text: t('New case'), disabled: disallowCaseCreation, onRenderField: newCaseOptionRender },
        { key: EnumCase.ExistingCase.toString(), text: archiveOnExistingDocumentEnabled ? t('Existing case or document') : t('Existing case') }
    ];

    const onChange = useCallback((ev?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        switch (option?.key) {
            case '1':
                setSelectedExistingCaseKey(EnumCase.NewCase);
                break;
            case '2':
            default:
                setSelectedExistingCaseKey(EnumCase.ExistingCase);
                break;
        }
    }, []);

    const saveClickHandler = useCallback((caseData: ICaseDocumentRequest, archiveOnExistingDocument?: boolean) => {
        caseData.selectionNewOrExisting = selectedExistingCaseKey;
        props.saveClickHandler(caseData, archiveOnExistingDocument);
    }, [props, selectedExistingCaseKey]);

    const continueClickHandler = useCallback((caseData: ICaseDocumentRequest, viewName: EnumViewName) => {
        caseData.selectionNewOrExisting = selectedExistingCaseKey;
        props.continueClickHandler(caseData, viewName);
    }, [props, selectedExistingCaseKey]);

    return <Stack tokens={verticalGapStackTokens}>
        <Stack>
            <Stack.Item align="auto" styles={viewSubHeaderAlignmentStyles}>
                <span style={viewSubHeaderContentAlignmentStyles}>{t('Case Information')}</span>
            </Stack.Item>
        </Stack>
        <Stack styles={viewAlignmentStackStyles} tokens={verticalGapStackTokens}>
            <Stack.Item>
                <span>{archiveOnExistingDocumentEnabled ? t('Do you want to create a new case or use existing case or document?') : t('Do you want to create a new case or use existing case?')}</span>
            </Stack.Item>
            <Stack.Item>
                <ChoiceGroup selectedKey={selectedExistingCaseKey} options={options} onChange={onChange} required={true} />
                {
                    selectedExistingCaseKey === EnumCase.NewCase ?
                        <NewCaseView {...props}
                            uiConguration={props.uiConguration}
                            continueClickHandler={continueClickHandler}
                            selectedFileName={props.caseDocumentData.documentTitle}
                            saveClickHandler={saveClickHandler}
                            closeClickHandler={closeClickHandler} />
                        :
                        <ExistingCaseView {...props}
                            continueClickHandler={continueClickHandler}
                            selectedCase={selectedCase}
                            selectedFileName={props.caseDocumentData.documentTitle}
                            saveClickHandler={saveClickHandler}
                            closeClickHandler={closeClickHandler}
                            archiveOnExistingDocumentEnabled={archiveOnExistingDocumentEnabled} />
                }
            </Stack.Item>
        </Stack>
    </Stack>;
};

export default CaseView;