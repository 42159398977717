import { FC } from "react";
import { IFileListItem } from "../../model/model";
import { Shimmer } from "@fluentui/react";
import { t } from "i18next";

interface IDocumentStatusFieldProps {
    item: IFileListItem,
    loading?: boolean
}

const DocumentStatusField: FC<IDocumentStatusFieldProps> = (props) => {
    const { item, loading } = props;

    if (!item.archivingStatusChecked && loading) {
        return <Shimmer aria-label={t('Loading...')} />;
    }

    if (item.documentStatus) {
        return <span>{item.documentStatus}</span>;
    }

    return null;
};

export default DocumentStatusField;
