import React, { FC, useState, useCallback } from 'react';
import { Stack, ChoiceGroup, IChoiceGroupOption, MessageBar } from '@fluentui/react';
import { verticalGapStackTokens } from '../../styles/MergeStyleSets';
import { ICommonProps } from '../ICommonProps';
import { EnumDocument, ICaseDocumentRequest, ISharepointDocumentItem } from '../../model/model';
import { useTranslation } from 'react-i18next';

interface MultiDocumentViewProps extends ICommonProps {
    selectedFiles: ISharepointDocumentItem[],
    caseDocumentData: ICaseDocumentRequest,
    singleDocumentHandler(singleOrMultipleDocument: EnumDocument): void,
    multiDocumentHandler(singleOrMultipleDocument: EnumDocument): void
}

const MultiDocumentView: FC<MultiDocumentViewProps> = (props) => {
    const { t } = useTranslation();
    const { selectedFiles, singleDocumentHandler, multiDocumentHandler } = props;
    const [selectedEnumDocument, setSelectedEnumDocument] = useState<EnumDocument>(() => {
        if (typeof props.caseDocumentData.createSingleOrMultipleDocument != 'undefined' && props.caseDocumentData.createSingleOrMultipleDocument)
            return props.caseDocumentData.createSingleOrMultipleDocument;
        else
            return EnumDocument.CreateMultipleDocument;
    });
    const options: IChoiceGroupOption[] = [
        { key: EnumDocument.CreateSingleDocument.toString(), text: t('Create one single document in 360 for all files') },
        { key: EnumDocument.CreateMultipleDocument.toString(), text: t('Create separate documents in 360 for each file') }
    ];
    const onChange = useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        switch (option?.key) {
            case '1':
                setSelectedEnumDocument(EnumDocument.CreateSingleDocument);
                singleDocumentHandler(EnumDocument.CreateSingleDocument);
                break;
            case '2':
            default:
                setSelectedEnumDocument(EnumDocument.CreateMultipleDocument);
                multiDocumentHandler(EnumDocument.CreateMultipleDocument);
                break;
        }
    }, [singleDocumentHandler, multiDocumentHandler]);

    return <Stack tokens={verticalGapStackTokens}>
        <MessageBar>
            {
                selectedEnumDocument === EnumDocument.CreateMultipleDocument ?
                    `${selectedFiles.length} ${t('separate document cards will be created in 360, one for each file')}`
                    : `${t('1 document card will be created  with the')} ${selectedFiles.length} ${t('files in 360')}`
            }
        </MessageBar>
        <ChoiceGroup selectedKey={selectedEnumDocument} options={options} onChange={onChange} required={true} />
    </Stack>;
};
export default MultiDocumentView;