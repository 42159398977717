import { app, authentication, pages } from "@microsoft/teams-js";
import { TeamsMockData } from "../mocks/mockData";
import { IMsTeamsProvider } from "./IMsTeamsProvider";

export class FakeMsTeamsProvider implements IMsTeamsProvider {
    initialize(): Promise<void> { return Promise.resolve(); }
    notifySuccess(): void { return; }
    notifyFailure(_appInitializationFailedRequest: app.IFailedRequest): void { return; }
    getSettings(): Promise<pages.InstanceConfig> {
        return Promise.resolve({
            contentUrl: `https://example.com?p360url=${process.env.REACT_APP_TEST_API_BASE_URL}`
        });
    }
    setSettings(_instanceSettings: pages.InstanceConfig): Promise<void> {
        return Promise.resolve();
    }
    setValidityState(_validityState: boolean): void { return; }
    getContext(): Promise<app.Context> {
        return Promise.resolve(TeamsMockData.appContext);
    }
    getAuthToken(_authTokenRequest?: authentication.AuthTokenRequestParameters): Promise<string> {
        return Promise.resolve(process.env.REACT_APP_TEST_API_ACCESS_TOKEN);
    }
}
