import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { ICommonProps } from '../ICommonProps';
import { EnumDocument, EnumUIConfiguration, ICaseDocumentRequest, ISharepointDocumentItem } from '../../model/model';
import DocumentView from './DocumentView';
import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { verticalGapStackTokens, viewSubHeaderAlignmentStyles, viewSubHeaderContentAlignmentStyles } from '../../styles/MergeStyleSets';
import MultiDocumentView from './MultiDocumentView';
import { useTranslation } from 'react-i18next';

interface IDocumentInformationViewProps extends ICommonProps {
    caseDocumentData: ICaseDocumentRequest,
    selectedFiles: ISharepointDocumentItem[],
    uiConguration: EnumUIConfiguration,
    singleDocumentHandler(): void,
    multiDocumentHandler(): void,
    saveClickHandler(caseData: ICaseDocumentRequest | undefined): void,
    previousClickHandler(caseData: ICaseDocumentRequest | undefined): void
}

const DocumentInformationView: FC<IDocumentInformationViewProps> = (props) => {
    const { t } = useTranslation();
    const [showDocumentTitleField, { setTrue: showDocumentTitle, setFalse: hideDocumentTitle }] = useBoolean(false);
    const [singleOrMultipleDocument, setSingleOrMultipleDocument] = useState<EnumDocument>(() => {
        if (typeof props.caseDocumentData.createSingleOrMultipleDocument != 'undefined' && props.caseDocumentData.createSingleOrMultipleDocument)
            return props.caseDocumentData.createSingleOrMultipleDocument;
        else
            return EnumDocument.CreateMultipleDocument;
    });

    const singleOrMultipleDocumentHandler = useCallback((singleOrMultipleDocument: EnumDocument) => {
        setSingleOrMultipleDocument(singleOrMultipleDocument);
        if (singleOrMultipleDocument === EnumDocument.CreateSingleDocument) {
            showDocumentTitle();
            props.singleDocumentHandler();
        }
        else {
            hideDocumentTitle();
            props.multiDocumentHandler();
        }
    }, [hideDocumentTitle, props, showDocumentTitle]);

    const previousClickHandler = useCallback((caseData: ICaseDocumentRequest) => {
        caseData.createSingleOrMultipleDocument = singleOrMultipleDocument;
        props.previousClickHandler(caseData);
    }, [props, singleOrMultipleDocument]);

    useEffect(() => {
        if (isSingleDocumentSelected(props.selectedFiles) || singleOrMultipleDocument === EnumDocument.CreateSingleDocument) {
            showDocumentTitle();
        }
    }, [props.selectedFiles, showDocumentTitle, singleOrMultipleDocument]);

    return <Stack tokens={verticalGapStackTokens}>
        <Stack>
            <Stack.Item align="auto" styles={viewSubHeaderAlignmentStyles}>
                <span style={viewSubHeaderContentAlignmentStyles}>{t('Document Information')}</span>
            </Stack.Item>
        </Stack>
        <Stack style={{ 'marginLeft': -30 }} tokens={verticalGapStackTokens}>
            {
                isSingleDocumentSelected(props.selectedFiles) === false ?
                    <span>
                        <MultiDocumentView {...props} singleDocumentHandler={singleOrMultipleDocumentHandler} multiDocumentHandler={singleOrMultipleDocumentHandler} />
                    </span>
                    :
                    <Fragment></Fragment>
            }
            <span>
                <DocumentView {...props} showDocumentTitleField={showDocumentTitleField} caseDocumentData={props.caseDocumentData} previousClickHandler={previousClickHandler} />
            </span>
        </Stack>
    </Stack >;
};

function isSingleDocumentSelected(getSelectedFiles?: ISharepointDocumentItem[]): boolean {
    if (getSelectedFiles && getSelectedFiles.length === 1) {
        if (!getSelectedFiles[0].isFolder)
            return true;
    }
    return false;
}

export default DocumentInformationView;