import React, { FC, useCallback, useState } from 'react';
import { Stack, DefaultButton, PrimaryButton } from '@fluentui/react';
import { buttonStyles, verticalGapStackTokens } from '../../styles/MergeStyleSets';
import { EnumSearchCriteria, EnumViewName, ICaseDocumentRequest, IDocumentResult, ISearchCase } from '../../model/model';
import { ICommonPropsArchivePanel } from '../ICommonProps';
import Searchbox from '../common/Searchbox';
import { t } from "i18next";

interface ExistingCaseViewProps extends ICommonPropsArchivePanel {
    selectedFileName?: string,
    selectedCase: string,
    selectedDocument?: string,
    caseDocumentData: ICaseDocumentRequest,
    saveClickHandler(caseData: ICaseDocumentRequest | undefined, archiveOnExistingDocument?: boolean): void,
    continueClickHandler(caseData: ICaseDocumentRequest | undefined, viewName: EnumViewName): void,
    closeClickHandler(): void,
    archiveOnExistingDocumentEnabled: boolean
}

const ExistingCaseView: FC<ExistingCaseViewProps> = (props) => {
    const requiredErrorMessage = props.archiveOnExistingDocumentEnabled ? t('Case or document selection is required') : t('Case selection is required');
    const [searchCaseCriteria] = useState<ISearchCase>(() => {
        if (typeof props.caseDocumentData?.caseNumber != 'undefined' && props.caseDocumentData?.caseNumber) {
            return { searchCriteria: props.caseDocumentData?.caseNumber, includeClosedCases: false };
        }

        if (typeof props.caseDocumentData?.documentNumber != 'undefined' && props.caseDocumentData?.documentNumber) {
            return { searchCriteria: props.caseDocumentData?.documentNumber, includeClosedCases: false };
        }

        return { searchCriteria: props.selectedCase ?? props.selectedDocument, includeClosedCases: false };
    });
    const [selectedCaseNumber, setSelectedCaseNumber] = React.useState<string>(() => {
        if (typeof props.caseDocumentData?.caseNumber != 'undefined' && props.caseDocumentData?.caseNumber)
            return props.caseDocumentData?.caseNumber;
        else
            return props.selectedCase;
    });
    const [selectedDocumentNumber, setSelectedDocumentNumber] = React.useState<string | undefined>(() => {
        if (typeof props.caseDocumentData?.documentNumber != 'undefined' && props.caseDocumentData?.documentNumber)
            return props.caseDocumentData?.documentNumber;
        else
            return props.selectedDocument;
    });
    const [existingCaseDocumentProps, setExistingCaseDocumentProps] = useState<ICaseDocumentRequest>(props.caseDocumentData);
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | undefined>(undefined);
    const [isDocumentSearch, setIsDocumentSearch] = useState<boolean>(false);

    const onSearch = useCallback((caseOrDocumentNumber: string, searchCriteria: EnumSearchCriteria, data?: IDocumentResult) => {
        if (caseOrDocumentNumber !== "") {
            setSearchErrorMessage(undefined);
        }
        if (searchCriteria === EnumSearchCriteria.Document) {
            setSelectedDocumentNumber(caseOrDocumentNumber);
            setExistingCaseDocumentProps(previousState => ({ ...previousState, documentNumber: caseOrDocumentNumber }));
        } else {
            setSelectedCaseNumber(caseOrDocumentNumber);
            setExistingCaseDocumentProps(previousState => ({ ...previousState, caseNumber: caseOrDocumentNumber }));
        }
    }, []);

    const onSearchOptionChange = useCallback((searchCriteria: EnumSearchCriteria) => {
        setIsDocumentSearch(searchCriteria === EnumSearchCriteria.Document);
    }, []);

    const continueClickHandler = useCallback(() => {
        if (selectedCaseNumber === "") {
            setSearchErrorMessage(requiredErrorMessage);
            return;
        }
        props.continueClickHandler({ ...existingCaseDocumentProps, caseNumber: selectedCaseNumber }, EnumViewName.CaseView);
    }, [props, existingCaseDocumentProps, selectedCaseNumber, requiredErrorMessage]);

    const saveClickHandler = useCallback(() => {
        if (selectedCaseNumber === "" && !selectedDocumentNumber) {
            setSearchErrorMessage(requiredErrorMessage);
            return;
        }
        const archiveOnExistingDocument = props.archiveOnExistingDocumentEnabled && !!selectedDocumentNumber;
        props.saveClickHandler({ ...existingCaseDocumentProps, caseNumber: selectedCaseNumber, documentNumber: selectedDocumentNumber }, archiveOnExistingDocument);
    }, [selectedCaseNumber, selectedDocumentNumber, props, existingCaseDocumentProps, requiredErrorMessage]);

    const searchCriteriaOptions = props.archiveOnExistingDocumentEnabled ? EnumSearchCriteria.All : EnumSearchCriteria.Case;
    const searchBoxWidth = props.archiveOnExistingDocumentEnabled ? 230 : 245;
    const primaryBtnOnClickHandler = props.isFileArchiveAction === true && !selectedDocumentNumber ? continueClickHandler : saveClickHandler;
    let primaryButtonText = t('Create connection');
    if (props.isFileArchiveAction) {
        primaryButtonText = isDocumentSearch ? t('Save to 360') : t('Continue');
    }

    return <div>
        <Stack style={{ marginLeft: -10 }} tokens={verticalGapStackTokens}>
            <Searchbox {...props}
                enumSearchCriteria={searchCriteriaOptions}
                includeClosedCases={false}
                searchText={searchCaseCriteria.searchCriteria}
                getDocumentsHandler={onSearch}
                searchBoxWidth={searchBoxWidth}
                searchErrorMessage={searchErrorMessage}
                onSearchOptionChange={onSearchOptionChange} />
        </Stack>
        <div>
            <PrimaryButton disabled={searchErrorMessage !== undefined} onClick={primaryBtnOnClickHandler} styles={buttonStyles}>{primaryButtonText}</PrimaryButton>
            <DefaultButton onClick={props.closeClickHandler}>{t('Close')}</DefaultButton>
        </div>
    </div >;
};
export default ExistingCaseView;