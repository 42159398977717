import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import { FC } from "react";
import i18n from '../../i18n';
import { useP360 } from "../../context/P360Context";
import { Constants } from "../../helpers/Constants";
import HelpIcon from '../../images/help.svg';
import { IFileListItem } from "../../model/model";
import { formatVersionForHelpUrl } from "../../helpers/Mapper";

interface ICommandBarContainerProps {
    selectedFiles?: IFileListItem[];
    caseConnectionHandler: () => void;
    archiveHandler: () => void;
    importHandler: () => void;
    clearSelection: () => void;
}

const CommandBarContainer: FC<ICommandBarContainerProps> = (props) => {
    const { p360State } = useP360();
    const { selectedFiles } = props;
    const hasSelectedFiles = selectedFiles && selectedFiles.length > 0;

    const commandItems: ICommandBarItemProps[] = [
        {
            key: 'caseconnection',
            text: i18n.t('Add 360 case'),
            iconProps: { iconName: 'Add' },
            disabled: !hasSelectedFiles || selectedFiles.some(f => !f.isFolder),
            onClick: () => {
                props.caseConnectionHandler();
            }
        },
        {
            key: 'archive',
            text: i18n.t('Archive to 360'),
            disabled: !hasSelectedFiles || selectedFiles.some(f => f.isFolder),
            iconProps: { iconName: 'refresh' },
            onClick: () => {
                props.archiveHandler();
            }
        }
    ];

    if (p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.Get360DocumentsWithFiles)
        && p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.ImportFiles)) {
        commandItems.push(
            {
                key: 'import',
                text: i18n.t('360° File Importer'),
                disabled: hasSelectedFiles && selectedFiles.filter(f => f.isFolder).length > 1,
                iconProps: { iconName: 'download' },
                onClick: () => {
                    props.importHandler();
                }
            }
        );
    }

    const farCommandItems: ICommandBarItemProps[] = [
        {
            key: 'helpMenu',
            iconProps:
            {
                imageProps: {
                    src: HelpIcon,
                    width: 16,
                    height: 16
                }
            },
            onClick: () => {
                window.open(i18n.t('HelpPageUrl', { p360version: formatVersionForHelpUrl(p360State.backendCapabilities?.Information?.Version) }), "_blank");
            }
        }
    ];

    if (hasSelectedFiles) {
        const fileSelectionKey = 'fileSelection';
        const fileSelectionCommandItemIndex = farCommandItems.findIndex(f => f.key === fileSelectionKey);
        if (fileSelectionCommandItemIndex !== -1) {
            farCommandItems[fileSelectionCommandItemIndex].text = `${selectedFiles.length} ${i18n.t('Selected')}`;
        }
        else {
            farCommandItems.push(
                {
                    key: fileSelectionKey,
                    text: `${selectedFiles.length} ${i18n.t('Selected')}`,
                    iconProps: { iconName: 'cancel' },
                    title: i18n.t('Clear selection'),
                    onClick: () => {
                        props.clearSelection();
                    }
                }
            );
        }
        farCommandItems.reverse();
    }
    return <CommandBar
        items={commandItems}
        farItems={farCommandItems}
        ariaLabel={i18n.t("Use left and right arrow keys to navigate between commands")}
    />;
};

export default CommandBarContainer;