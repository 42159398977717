import React, { FC, useCallback, useEffect, useState } from 'react';
import { TextField, DefaultButton, ITextFieldProps, Link, PrimaryButton, Stack } from '@fluentui/react';
import { buttonStyles, itemStyles, verticalGapStackTokens, viewSubHeaderAlignmentStyles, viewSubHeaderContentAlignmentStyles } from '../../styles/MergeStyleSets';
import { EnumUIConfiguration, EnumViewName, ICaseDocumentRequest, ISharepointDocumentItem } from '../../model/model';
import { ICommonPropsArchivePanel } from '../ICommonProps';
import CaseView from './CaseView';
import { useTranslation } from 'react-i18next';
import { useId } from '@fluentui/react-hooks';

interface IChangeCaseViewProps extends ICommonPropsArchivePanel {
    saveClickHandler(caseData: ICaseDocumentRequest | undefined): void,
    continueClickHandler(caseData: ICaseDocumentRequest | undefined, viewName: EnumViewName): void,
    closeClickHandler(): void,
    selectedFiles?: ISharepointDocumentItem[],
    selectedCase: string,
    caseDocumentData: ICaseDocumentRequest,
    uiConguration: EnumUIConfiguration
}

const ChangeCaseView: FC<IChangeCaseViewProps> = (props) => {
    const { t } = useTranslation();
    const [changeCase, setChangeCase] = useState(false);

    const onChangeCaseClick = useCallback((_ev: React.MouseEvent<unknown>) => {
        setChangeCase(true);
    }, []);
    const [caseTitle, setCaseTitle] = useState<string>("");
    const labelId: string = useId('label');
    const onRenderLabel = useCallback((_props: ITextFieldProps | undefined) => {
        return <Stack id={labelId} horizontal horizontalAlign="space-between">
            <span style={itemStyles}>{t('Selected case')}</span>
            <span style={itemStyles}>
                <Link onClick={onChangeCaseClick} underline>
                    {t('Change case')}
                </Link></span>
        </Stack>;
    }, [labelId, onChangeCaseClick, t]);

    const onContinueClick = useCallback(() => {
        props.continueClickHandler(props.caseDocumentData, EnumViewName.ChangeCaseView);
    }, [props]);

    const getCases = useCallback(() => {
        props.dataProvider?.P360?.getCases({ searchCriteria: props.selectedCase, includeClosedCases: false }).then((res) => {
            if (res.data != null && res.data.length > 0) {
                setCaseTitle(`${res.data[0].CaseNumber} - ${res.data[0].Title}`);
            }
        });
    }, [props.dataProvider?.P360, props.selectedCase]);

    useEffect(() => {
        if (props.selectedCase === null)
            setChangeCase(true);
        else
            getCases();
    }, [props.selectedCase, getCases]);
    if (!changeCase) {
        return <Stack tokens={verticalGapStackTokens}>
            <Stack>
                <Stack.Item align="auto" styles={viewSubHeaderAlignmentStyles}>
                    <span style={viewSubHeaderContentAlignmentStyles}>{t('Case Information')}</span>
                </Stack.Item>
            </Stack>

            <Stack style={{ marginLeft: -10 }}>
                <TextField value={caseTitle} readOnly={true} resizable={false} aria-labelledby={labelId} onRenderLabel={onRenderLabel} />
            </Stack>
            <div style={{ marginLeft: -10 }}>
                <PrimaryButton onClick={onContinueClick} styles={buttonStyles}>
                    {t('Continue')}
                </PrimaryButton>

                <DefaultButton onClick={props.closeClickHandler}>{t('Close')}</DefaultButton>
            </div>
        </Stack>;
    }
    else {
        return <CaseView {...props}
            uiConguration={props.uiConguration}
            selectedCase={props.selectedCase}
            saveClickHandler={props.saveClickHandler}
            continueClickHandler={props.continueClickHandler}
            closeClickHandler={props.closeClickHandler} />;
    }
};

export default ChangeCaseView;