import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { CombineUrl } from "../helpers/UrlHelper";
import { ITeamServiceRequest, ISharepointFileResponse, IAccessGroupResponse, IBackendCapabilities, ICaseDocumentResponse, ICaseResponse, IClassCode, IClassCodeResponse, IEntityHistoryItem, IGetDocumentRequest, IGetHistoryParameterRequest, IGetSearchParameterRequest, IPublicityClassResponse, ISearchCase, ArchiveFileOnExistingDocumentRequestParameter, AppPermissionsRequestParameter, SyncFileWithTeamsRequestParameter, EnumGraphApiAuthMode } from "../model/model";
import { IP360Provider } from "./IP360Provider";

const apiPath = "/Biz/v2/api/call/SI.Client.Api.Custom.Teams/SI.Client.Api.Custom.Teams/TeamsService";

export class P360Provider implements IP360Provider {
    public httpClient?: AxiosInstance;

    initialize(baseUrl: string, getToken?: () => Promise<string>): void {
        this.httpClient = axios.create({
            baseURL: CombineUrl(baseUrl, apiPath),
            withCredentials: true,
        });

        if(getToken){
            this.httpClient.interceptors.request.use(async (config) => {
                const token = await getToken();
                config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
                return config;
            });
        }

        if (process.env.REACT_APP_USE_AUTHKEY === 'true' && process.env.REACT_APP_TEST_API_AUTHKEY) {
            this.httpClient.interceptors.request.use((config) => {
                config.params = { ...config.params, authkey: process.env.REACT_APP_TEST_API_AUTHKEY };
                return config;
            });
        }
    }

    public checkConnection(): Promise<AxiosResponse<{ Status: "ok"; }>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<{ Status: 'ok' }>("Ping");
    }

    public getChannelFiles(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("GetChannelFiles", data);
    }

    public getFileItems(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("GetFileItems", data);
    }

    public getAccessGroups(): Promise<AxiosResponse<IAccessGroupResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post("GetAccessGroup");
    }

    public getCases(data: ISearchCase): Promise<AxiosResponse<ICaseResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseResponse[]>("GetCases", data);
    }
    public addConnectionToNewCase(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("AddConnectionToNewCase", data);
    }

    public addConnectionToExistingCase(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("AddConnectionToExistingCase", data);
    }

    public archiveFilesAsSingleDocument(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("ArchiveFile", data);
    }
    public checkIfFileItemIsArchived(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("CheckIfFileItemIsArchived", data);
    }
    public getFilesArchivedStatus(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("GetFilesArchivedStatus", data);
    }
    public getProcess(data: ISearchCase): Promise<AxiosResponse<IClassCodeResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCodeResponse>("GetProcess", data);
    }
    public getRecordTypes(data: ITeamServiceRequest): Promise<AxiosResponse<IClassCode[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCode[]>("GetRecordTypes", data);
    }
    public getConfigurationType(): Promise<AxiosResponse<IBackendCapabilities>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IBackendCapabilities>("GetConfigurationType");
    }
    public getPublicityClasses(): Promise<AxiosResponse<IPublicityClassResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IPublicityClassResponse[]>("GetPublicityClass");
    }
    public getActionTypes(data: ITeamServiceRequest): Promise<AxiosResponse<IClassCode[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCode[]>("GetActionTypes", data);
    }
    public getRecordTypesBasedOnActionType(data: ITeamServiceRequest): Promise<AxiosResponse<IClassCode[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IClassCode[]>("GetRecordTypesBasedOnActionType", data);
    }
    public getDocuments(data: IGetDocumentRequest): Promise<AxiosResponse<ICaseDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseDocumentResponse>("GetDocuments", data);
    }
    public getDocumentsByDocumentNumber(data: IGetDocumentRequest): Promise<AxiosResponse<ICaseDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseDocumentResponse>("GetDocumentsByDocumentNumber", data);
    }
    public importFiles(data: ITeamServiceRequest): Promise<AxiosResponse<ISharepointFileResponse[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse[]>("ImportFiles", data);
    }
    public getHistory(data: IGetHistoryParameterRequest): Promise<AxiosResponse<IEntityHistoryItem[]>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<IEntityHistoryItem[]>("GetHistory", { parameter: data });
    }
    public searchDocuments(data: IGetSearchParameterRequest): Promise<AxiosResponse<ICaseDocumentResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ICaseDocumentResponse>("SearchDocuments", { parameter: data });
    }
    checkAppPermissions(data: AppPermissionsRequestParameter): Promise<AxiosResponse<boolean>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<boolean>("CheckAppPermissions", { parameter: data });
    }
    setAppPermissions(data: AppPermissionsRequestParameter): Promise<AxiosResponse<boolean>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<boolean>("SetAppPermissions", { parameter: data });
    }
    public archiveFileOnExistingDocument(data: ArchiveFileOnExistingDocumentRequestParameter): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        return this.httpClient.post<ISharepointFileResponse>("ArchiveFileOnExistingDocument", { parameter: data });
    }
    public syncFileWithTeams(data: SyncFileWithTeamsRequestParameter): Promise<AxiosResponse<ISharepointFileResponse>> {
        if (!this.httpClient) { throw new Error("HttpClient is not initialized."); }
        data.enumGraphApiAuthMode = EnumGraphApiAuthMode.IdToken;
        return this.httpClient.post<ISharepointFileResponse>("SyncFileWithTeams", data);
    }
}