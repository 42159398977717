import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react";
import { t } from "i18next";
import { FC } from "react";
import { useFiles } from "../../context/FilesContext";
import { Constants } from "../../helpers/Constants";
import { IFileListItem } from "../../model/model";
import { Features } from "../../config/Features";

interface IBreadcrumbContainerProps {
    clearSelectedFiles?: () => void;
}

const BreadcrumbContainer: FC<IBreadcrumbContainerProps> = (props) => {
    const { navigateToFolder, allFiles, currentFolder } = useFiles();

    function getBreadcrumbPath(): IBreadcrumbItem[] {
        const onBreadcrumbClick = (_ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem): void => {
            if (Features.UseNewLoading) {
                props.clearSelectedFiles?.();
            }
            navigateToFolder(item?.key as string);
        };

        const breadcrumbs: IBreadcrumbItem[] = [{ key: Constants.HomeDir, text: Constants.HomeDir, onClick: onBreadcrumbClick }];
        if (currentFolder?.fileId === Constants.HomeDir || !currentFolder?.parentId || !allFiles?.length) {
            breadcrumbs[0].isCurrentItem = true;
            return breadcrumbs;
        }

        const tempArray: IBreadcrumbItem[] = [];
        tempArray.push({ key: currentFolder.fileId as string, text: currentFolder.fileName as string, onClick: onBreadcrumbClick, isCurrentItem: true });
        let tempItem = currentFolder;
        while (tempItem.parentId && tempItem.parentId !== Constants.HomeDir) {
            const tempParent = tempItem.parentId;
            tempItem = allFiles.find(file => file.fileId === tempParent) as IFileListItem;
            tempArray.push({ key: tempItem.fileId as string, text: tempItem.fileName as string, onClick: onBreadcrumbClick });
        }

        return breadcrumbs.concat([...tempArray].reverse());
    }

    return <Breadcrumb
        items={getBreadcrumbPath()}
        style={{ marginLeft: '24px', marginTop: '0px' }}
        overflowAriaLabel={t("More links")}
    />;
};

export default BreadcrumbContainer;