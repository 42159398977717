import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { IconButton, Link, MessageBar, MessageBarType, Modal } from '@fluentui/react';
import { ErrorType, useApiErrorHandler } from '../../context/ApiErrorHandler';
import { t } from "i18next";
import { modalContentStyles, modalIconButtonStyles } from '../../styles/MergeStyleSets';

export interface ErrorNotificationProps {
    errorProp?: ErrorType;
}

const ErrorNotification: React.FC<ErrorNotificationProps> = (props) => {
    const { error, removeError } = useApiErrorHandler();
    const currentError = props.errorProp ?? error;
    const onErrorDismiss = props.errorProp ? () => { return; } : removeError;

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const titleId = useId('error_title');

    // Some error responses might contain request's headers and authentication token, remove those from output.
    // This is to prevent sending authentication information accidentally over email for example on error solving situations.
    let errorDetails = "";
    if (currentError?.response?.data) {
        try {
            errorDetails = JSON.stringify(currentError?.response?.data);
            if (typeof errorDetails === 'string' && errorDetails?.indexOf("HEADERS") > -1) {
                errorDetails = errorDetails.substring(0, errorDetails.indexOf("HEADERS"));
            }
        } catch { /* ignore */ }
    }

    return <span>
        {currentError &&
            <div>
                <MessageBar messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={onErrorDismiss}
                    dismissButtonAriaLabel={t('Close')}>
                    {t('An error occurred during the processing of the request')} <Link onClick={showModal}>{t('Error details')}</Link>
                </MessageBar>
                <Modal
                    titleAriaId={titleId}
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    isBlocking={false}
                    containerClassName={modalContentStyles.container}>
                    <div className={modalContentStyles.header}>
                        <span id={titleId}>{t('Error')}</span>
                        <IconButton
                            styles={modalIconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel={t('Close')}
                            onClick={hideModal}
                        />
                    </div>
                    <div className={modalContentStyles.body}>
                        <p><strong>{t('An error occurred during the processing of the request')}</strong></p>
                        <p><strong>{t('Error details')}:</strong></p>
                        <p>{currentError.message}</p>
                        <p>{currentError.stack}</p>
                        <p>{errorDetails}</p>
                    </div>
                </Modal>
            </div>
        }
    </span>;
};

export default ErrorNotification;
