import { ProgressIndicator } from "@fluentui/react";
import { t } from "i18next";
import { FC } from "react";
import { useFiles } from "../../context/FilesContext";
import { progressIndicatorStyles } from "../../styles/MergeStyleSets";

const ProgressContainer: FC = () => {
    const { loadingInfo } = useFiles();
    return <div style={{ height: '20px' }}>
        {loadingInfo.currentFolderIsLoading && <ProgressIndicator description={t("Loading...")} barHeight={4} styles={progressIndicatorStyles} />}
    </div>;
};

export default ProgressContainer;