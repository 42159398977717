import React, { useEffect, FC, useState, useCallback, useRef } from 'react';
import { Stack, TextField, Dropdown, ComboBox, DefaultButton, IButtonProps, IComboBox, IComboBoxOption, IDropdownOption, IIconProps, PrimaryButton } from '@fluentui/react';
import { verticalGapStackTokens, dropdownStyles, buttonStyles, disabledComboboxButtonBackground } from '../../styles/MergeStyleSets';
import { ICommonPropsArchivePanel } from '../ICommonProps';
import { EnumNewCaseFieldsError, EnumUIConfiguration, EnumViewName, ICaseDocumentRequest, ISearchCase } from '../../model/model';
import { t } from "i18next";
import { FileType } from '../../helpers/FileType';
import { Constants } from '../../helpers/Constants';
import { sortData } from '../../helpers/Mapper';

interface NewCaseViewProps extends ICommonPropsArchivePanel {
    selectedFileName?: string,
    caseDocumentData: ICaseDocumentRequest,
    uiConguration: EnumUIConfiguration,
    saveClickHandler(caseData: ICaseDocumentRequest | undefined): void,
    continueClickHandler(caseData: ICaseDocumentRequest | undefined, viewName: EnumViewName): void,
    closeClickHandler(): void
}

const NewCaseView: FC<NewCaseViewProps> = (props) => {
    const [newCaseDocumentProps, setNewCaseDocumentProps] = useState<ICaseDocumentRequest>({ ...props.caseDocumentData, caseTitle: FileType.removeExtensionFromFileName(props.caseDocumentData.caseTitle)?.substring(0, Constants.CaseTitleMaxLength) });
    const [accessGroupOptions, setAccessGroupOptions] = useState<IDropdownOption[]>([]);
    const [processOptions, setProcessOptions] = useState<IDropdownOption[]>([]);
    const [publicityClassOption, setPublicityClassOption] = useState<IDropdownOption[]>([]);
    const [showCaseTitleError, setShowCaseTitleError] = useState(false);
    const [showAccessGroupError, setShowAccessGroupError] = useState(false);
    const [processErrorMessage, setProcessErrorMessage] = useState<string>();
    const [showProcessError, setShowProcessError] = useState(false);
    const [publicityClassErrorMessage, setPublicityClassErrorMessage] = useState<string>();
    const [showPublicityClassError, setShowPublicityClassError] = useState(false);
    const [processLabel, setProcessLabel] = useState<string>();
    const zeroRecno = 0;
    //Removing the caret down icon from combobox
    const buttonIconProps: IButtonProps = { disabled: true, style: disabledComboboxButtonBackground };
    const iconProps: IIconProps = { iconName: "" };
    const [selectedProcess, setSelectedProcess] = useState(() => {
        if (typeof props.caseDocumentData?.processRecno != 'undefined' && props.caseDocumentData?.processRecno)
            return props.caseDocumentData?.processRecno;
        else
            return -1;
    });
    const [searchProcessCriteria, setSearchProcessCriteria] = useState<ISearchCase>(() => {
        if (typeof props.caseDocumentData?.processName != 'undefined' && props.caseDocumentData?.processName)
            return { searchCriteria: props.caseDocumentData?.processName, includeClosedCases: false };
        else
            return { searchCriteria: "", includeClosedCases: false };
    });
    const [selectedPublicityClass, setSelectedPublicityClass] = useState<number>();
    const [selectedAccessGroup, setSelectedAccessGroup] = useState<number>();
    const publicityClassError = t('The selected Publicity class cannot be used for case creation');
    const processAccessCodeError = t('The selected value can not be used from Teams because the connected access code is not public');
    const personalDataError = t('Personal data does not have a value');
    const requiredError = t('Required');
    const processComboBoxRef = useRef<IComboBox>(null);
    const setErrorOnProcess = useCallback((error: boolean, processError?: EnumNewCaseFieldsError) => {
        if (error) {
            setShowProcessError(true);
            switch (processError) {
                case EnumNewCaseFieldsError.Required:
                    setProcessErrorMessage(requiredError);
                    break;
                case EnumNewCaseFieldsError.AccessCode:
                    setProcessErrorMessage(processAccessCodeError);
                    break;
                case EnumNewCaseFieldsError.PersonalData:
                    setProcessErrorMessage(personalDataError);
                    break;
            }
        }
        else {
            setShowProcessError(false);
            setProcessErrorMessage("");
        }
    }, [personalDataError, processAccessCodeError, requiredError]);

    const setErrorOnPublicityClass = useCallback((error: boolean, publicityError?: EnumNewCaseFieldsError) => {
        if (error) {
            setShowPublicityClassError(true);
            switch (publicityError) {
                case EnumNewCaseFieldsError.Required:
                    setPublicityClassErrorMessage(requiredError);
                    break;
                case EnumNewCaseFieldsError.PublicityClass:
                    setPublicityClassErrorMessage(publicityClassError);
                    break;
            }
        }
        else {
            setShowPublicityClassError(false);
            setPublicityClassErrorMessage("");
        }
    }, [publicityClassError, requiredError]);

    const getAccessGroups = useCallback(() => {
        setAccessGroupOptions([]);
        props.dataProvider?.P360?.getAccessGroups().then((res) => {
            for (const element of res.data)
                setAccessGroupOptions(accessGroup => [...accessGroup, { key: element.Recno, text: element.Code }]);

            if (typeof props.caseDocumentData?.accessGroup !== 'undefined' && props.caseDocumentData?.accessGroup !== "")
                setSelectedAccessGroup(res.data.find(ag => ag.Code === props.caseDocumentData?.accessGroup)?.Recno);
        });
    }, [props.dataProvider?.P360, props.caseDocumentData?.accessGroup]);

    const getProcesses = useCallback(() => {
        setProcessOptions([]);
        props.dataProvider?.P360?.getProcess(searchProcessCriteria).then((res) => {
            if (res.data.ClassCodes) {
                res.data.ClassCodes = [...res.data.ClassCodes].sort(sortData);
                for (const element of res.data.ClassCodes)
                    setProcessOptions(process => [...process, { key: element.Recno, text: element.CodeAndDescription, data: element }]);
                if (searchProcessCriteria)
                    processComboBoxRef.current?.focus(true);
            }
            setProcessLabel(res.data.Label);
        });
    }, [props.dataProvider?.P360, searchProcessCriteria]);

    const getPublicityClasses = useCallback(() => {
        setPublicityClassOption([]);
        props.dataProvider?.P360?.getPublicityClasses().then((res) => {
            for (const element of res.data)
                setPublicityClassOption(publicityClass => [...publicityClass, { key: element.Recno, text: element.Code, data: element }]);

            if (typeof props.caseDocumentData?.publicityClassRecno != 'undefined' && props.caseDocumentData?.publicityClassRecno > zeroRecno)
                setSelectedPublicityClass(props.caseDocumentData?.publicityClassRecno);
        });
    }, [props.dataProvider?.P360, props.caseDocumentData?.publicityClassRecno]);

    const onAccessGroupChange = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        setNewCaseDocumentProps(previousState => ({ ...previousState, accessGroup: option?.text }));
        setSelectedAccessGroup(Number(option?.key));
        setShowAccessGroupError(false);
    }, []);

    const onCaseTitleChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setNewCaseDocumentProps(previousState => ({ ...previousState, caseTitle: newValue }));
        if (newValue?.trim() === "")
            setShowCaseTitleError(true);
        else
            setShowCaseTitleError(false);
    }, []);

    const onProcessChange = useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (option) {
            if (!option?.data.IsAccessCodeSupportable)
                setErrorOnProcess(true, EnumNewCaseFieldsError.AccessCode);
            else
                setErrorOnProcess(false);

            setSelectedProcess(Number(option?.key));
            setNewCaseDocumentProps(previousState => ({ ...previousState, processRecno: Number(option?.key), processName: option?.data.Description, publicityClassRecno: Number(option?.data?.PublicityClass.Recno), personalDataRecno: Number(option?.data?.PersonalDataRecno) }));

            if (props.uiConguration !== EnumUIConfiguration.Sahke2) { return; }

            setSelectedPublicityClass(option?.data?.PublicityClass.Recno ?? zeroRecno);

            if (option?.data?.PublicityClass.IsSupportable)
                setErrorOnPublicityClass(false);
            else
                setErrorOnPublicityClass(true, EnumNewCaseFieldsError.PublicityClass);

            if (option?.data?.PersonalDataRecno === zeroRecno)
                setErrorOnProcess(true, EnumNewCaseFieldsError.PersonalData);

            return;
        }
        else {
            setErrorOnProcess(true, EnumNewCaseFieldsError.Required);
            setSelectedProcess(-1);
        }

        setProcessOptions([]);
        setSearchProcessCriteria({ searchCriteria: value ?? "", includeClosedCases: false });
    }, [props.uiConguration, setErrorOnProcess, setErrorOnPublicityClass]);

    const onPublicityClassChange = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        if (!option?.data?.IsSupportable)
            setErrorOnPublicityClass(true, EnumNewCaseFieldsError.PublicityClass);
        else
            setErrorOnPublicityClass(false);

        setSelectedPublicityClass(Number(option?.key));
        setNewCaseDocumentProps(previousState => ({ ...previousState, publicityClassRecno: Number(option?.key) }));
    }, [setErrorOnPublicityClass]);

    const validateInputs = useCallback((): boolean => {
        let isValid = true;
        if (newCaseDocumentProps.caseTitle?.trim() === "") {
            setShowCaseTitleError(true);
            isValid = false;
        }

        if (props.uiConguration === EnumUIConfiguration.ProcessView || props.uiConguration === EnumUIConfiguration.Sahke2) {
            if (typeof newCaseDocumentProps.processRecno === 'undefined' || newCaseDocumentProps.actionTypeRecno === zeroRecno) {
                setErrorOnProcess(true, EnumNewCaseFieldsError.Required);
                isValid = false;
            }
        }
        if (props.uiConguration === EnumUIConfiguration.Sahke2) {
            if (typeof newCaseDocumentProps.publicityClassRecno === 'undefined' || newCaseDocumentProps.publicityClassRecno === zeroRecno) {
                setErrorOnPublicityClass(true, EnumNewCaseFieldsError.Required);
                isValid = false;
            }
        }
        if (typeof newCaseDocumentProps.accessGroup === 'undefined') {
            setShowAccessGroupError(true);
            isValid = false;
        }
        if (showProcessError === false && showPublicityClassError === false && isValid)
            return true;
        else
            return false;
    }, [newCaseDocumentProps.accessGroup, newCaseDocumentProps.actionTypeRecno, newCaseDocumentProps.caseTitle, newCaseDocumentProps.processRecno, newCaseDocumentProps.publicityClassRecno, props.uiConguration, setErrorOnProcess, setErrorOnPublicityClass, showProcessError, showPublicityClassError]);

    const continueClickHandler = useCallback(() => {
        if (validateInputs())
            props.continueClickHandler(newCaseDocumentProps, EnumViewName.CaseView);
    }, [newCaseDocumentProps, props, validateInputs]);

    const saveClickHandler = useCallback(() => {
        if (validateInputs())
            props.saveClickHandler(newCaseDocumentProps);
    }, [newCaseDocumentProps, props, validateInputs]);

    useEffect(() => {
        getAccessGroups();
        if (props.uiConguration === EnumUIConfiguration.ProcessView || props.uiConguration === EnumUIConfiguration.Sahke2)
            getProcesses();
        if (props.uiConguration === EnumUIConfiguration.Sahke2)
            getPublicityClasses();
    }, [getAccessGroups, props.uiConguration, getProcesses, getPublicityClasses]);

    const isDisabled = showCaseTitleError || showAccessGroupError || showProcessError || showPublicityClassError;

    return <div>
        <Stack style={{ marginLeft: -10 }} tokens={verticalGapStackTokens}>
            <TextField label={t('Case title')}
                value={newCaseDocumentProps.caseTitle}
                required
                multiline
                maxLength={Constants.CaseTitleMaxLength}
                resizable={false}
                onChange={onCaseTitleChange}
                errorMessage={showCaseTitleError ? requiredError : undefined} />
            {
                (props.uiConguration === EnumUIConfiguration.ProcessView || props.uiConguration === EnumUIConfiguration.Sahke2) &&
                <ComboBox placeholder={t('Select an option')}
                    buttonIconProps={iconProps}
                    iconButtonProps={buttonIconProps}
                    required={true}
                    selectedKey={selectedProcess}
                    label={processLabel}
                    componentRef={processComboBoxRef}
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={processOptions}
                    styles={dropdownStyles}
                    onChange={onProcessChange}
                    errorMessage={showProcessError ? processErrorMessage : undefined} />
            }
            {
                props.uiConguration === EnumUIConfiguration.Sahke2 &&
                <Dropdown placeholder={t('Select an option')}
                    required
                    selectedKey={selectedPublicityClass}
                    label={t('Publicity class')}
                    options={publicityClassOption}
                    styles={dropdownStyles}
                    onChange={onPublicityClassChange}
                    errorMessage={showPublicityClassError ? publicityClassErrorMessage : undefined} />
            }
            <Dropdown placeholder={t('Select an option')}
                required
                selectedKey={selectedAccessGroup}
                label={t('Access group')}
                options={accessGroupOptions}
                styles={dropdownStyles}
                onChange={onAccessGroupChange}
                errorMessage={showAccessGroupError ? requiredError : undefined} />
        </Stack>
        <div>
            {
                props.isFileArchiveAction === true ?
                    <PrimaryButton onClick={continueClickHandler} styles={buttonStyles} disabled={isDisabled}>
                        {t('Continue')}
                    </PrimaryButton>
                    :
                    <PrimaryButton onClick={saveClickHandler} styles={buttonStyles} disabled={isDisabled}>
                        {t('Create connection')}
                    </PrimaryButton>
            }
            <DefaultButton onClick={props.closeClickHandler}>{t('Close')}</DefaultButton>
        </div>
    </div >;
};

export default NewCaseView;