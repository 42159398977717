import { useP360 } from "../context/P360Context";
import { useTeams } from "../context/TeamsContext";

export class SessionStorageProvider {
    public static readonly FilesStateKey = "filesState";
    public static readonly CurrentFolderKey = "currentFolder";
    private readonly prefix: string;

    constructor(p360Url: string, channelId: string, teamId: string, userId: string) {
        this.prefix = `p360Tab_${p360Url}_${channelId}_${teamId}_${userId}_`;
    }
   
    public setItem(key: string, value: unknown): void {
        try {
            sessionStorage.setItem(this.prefix + key, JSON.stringify(value));
        } catch (e) {
            // do nothing
        }
    }

    public getItem<T>(key: string): T | null {
        try {
            const value = sessionStorage.getItem(this.prefix + key);
            return value ? JSON.parse(value) as T : null;
        } catch (e) {
            return null;
        }
    }

    public removeItem(key: string): void {
        try {
            sessionStorage.removeItem(this.prefix + key);
        } catch (e) {
            // do nothing
        }
    }
}

export function useSessionStorageProvider() {
    const { teamsState } = useTeams();
    const { p360State } = useP360();
    return new SessionStorageProvider(p360State.p360Url, teamsState.userContext?.channel?.id as string, teamsState.userContext?.team?.internalId as string, teamsState.userContext?.user?.id as string);
}