import { ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, ThemeProvider } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";
import ErrorNotification from "../error/ErrorNotification";
import CommandBarContainer from "./CommandBarContainer";
import BreadcrumbContainer from "./BreadcrumbContainer";
import FileList from "./FileList";
import ProgressContainer from "./ProgressContainer";
import ImportPanelContainer from "../rightPanel/ImportPanelContainer";
import { DataProvider } from "../../providers/DataProvider";
import { IFileListItem } from "../../model/model";
import ArchivePanelContainer from "../rightPanel/ArchivePanelContainer";
import { classNames } from "../../styles/MergeStyleSets";
import { useApiErrorHandler } from "../../context/ApiErrorHandler";
import { Features } from "../../config/Features";

interface IArchiveScreenLayoutProps {
    dataProvider: DataProvider;
}

const ArchiveScreenLayout: FC<IArchiveScreenLayoutProps> = (props) => {
    const [isArchivePanelOpen, setIsArchivePanelOpen] = useState(false);
    const [isCaseConnectionPanelOpen, setIsCaseConnectionPanelOpen] = useState(false);
    const [isImportPanelOpen, setIsImportPanelOpen] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState<IFileListItem[]>([]);

    const { error } = useApiErrorHandler();

    useEffect(() => {
        if (error) {
            setIsArchivePanelOpen(false);
            setIsCaseConnectionPanelOpen(false);
            setIsImportPanelOpen(false);
        }
    }, [error]);

    return <ThemeProvider className={classNames.wrapper}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{ overflowX: "hidden" }}>
            <Sticky stickyPosition={StickyPositionType.Header}>
                <ErrorNotification />
                <CommandBarContainer
                    selectedFiles={selectedFiles}
                    archiveHandler={useCallback(() => { setIsArchivePanelOpen(true); }, [])}
                    caseConnectionHandler={useCallback(() => { setIsCaseConnectionPanelOpen(true); }, [])}
                    importHandler={useCallback(() => { setIsImportPanelOpen(true); }, [])}
                    clearSelection={useCallback(() => { setSelectedFiles([]); }, [])} />
                {!Features.UseNewLoading && <ProgressContainer />}
                <BreadcrumbContainer clearSelectedFiles={useCallback(() => { setSelectedFiles([]); }, [])} />
            </Sticky>
            <FileList
                dataProvider={props.dataProvider}
                setSelectedFiles={setSelectedFiles}
                selectedFiles={selectedFiles} />
        </ScrollablePane>
        <ArchivePanelContainer
            dataProvider={props.dataProvider}
            selectedFiles={selectedFiles}
            clearSelectedFiles={useCallback(() => { setSelectedFiles([]); }, [])}
            isOpen={isArchivePanelOpen || isCaseConnectionPanelOpen}
            onDismiss={useCallback(() => { setIsArchivePanelOpen(false); setIsCaseConnectionPanelOpen(false); }, [])}
            isFileArchiveAction={isArchivePanelOpen} />
        <ImportPanelContainer
            dataProvider={props.dataProvider}
            selectedFiles={selectedFiles}
            isOpen={isImportPanelOpen}
            onDismiss={useCallback(() => setIsImportPanelOpen(false), [])} />
    </ThemeProvider>;
};

export default ArchiveScreenLayout;