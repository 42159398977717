interface FileTypeProps {
    docType: string,
    url: string
}
const FILE_ICONS: { name: string }[] = [
    { name: 'folder' },
    { name: 'accdb' },
    { name: 'audio' },
    { name: 'code' },
    { name: 'csv' },
    { name: 'docx' },
    { name: 'dotx' },
    { name: 'mpp' },
    { name: 'mpt' },
    { name: 'model' },
    { name: 'one' },
    { name: 'onetoc' },
    { name: 'potx' },
    { name: 'ppsx' },
    { name: 'pdf' },
    { name: 'photo' },
    { name: 'pptx' },
    { name: 'presentation' },
    { name: 'potx' },
    { name: 'pub' },
    { name: 'rtf' },
    { name: 'spreadsheet' },
    { name: 'txt' },
    { name: 'vector' },
    { name: 'vsdx' },
    { name: 'vssx' },
    { name: 'vstx' },
    { name: 'xlsx' },
    { name: 'xltx' },
    { name: 'xsn' }
];
export class FileType {
    public static getfileType(fileName: string, isFolderType = false): FileTypeProps {
        let iconName = 'presentation';
        let docType = 'presentation';
        if (isFolderType) {
            iconName = docType = "folder";
        }
        else if (fileName && fileName.lastIndexOf('.') !== -1) {
            const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
            docType = FILE_ICONS.find(x => x.name === fileExtension.toLowerCase())?.name || 'presentation';
            iconName = docType;
        }
        return {
            docType,
            url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${iconName}.svg`,
        };
    }

    public static removeExtensionFromFileName(file?: string): string {
        if (file) {
            const fileName = file.substring(0, file.lastIndexOf('.')).trim();
            return fileName === '' ? file : fileName;
        }
        return '';
    }
}
